<template>
  <div>
    <div class="tooltip">
      <month-picker-input
        class="is-size-5 column is-full is-full-mobile"
        @input="refreshCard"
        v-if="isPast == true"
      ></month-picker-input>
      <div v-else class="column mt-6"></div>
      <span class="tooltiptext" v-if="isPast == true"
        >Select a month to view below</span
      >
    </div>

    <div class="box has-background-white-bis">
      <div class="mb-5">
        <span class="is-size-3 has-text-weight-bold">{{
          monthlyData.month
        }}</span>
      </div>
      <div class="has-text-underlined mb-2">
        <span class="is-size-4 has-text-weight-bold"
          >Total of alerts: {{ monthlyData.total }}</span
        >
      </div>
      <div class="has-text-underlined mb-2 tooltip">
        <span
          class="is-size-5 has-text-weight-bold has-text-primary"
          @click="goToAlerts('nonconformity')"
          style="cursor: pointer"
          >PPE: {{ monthlyData.nonconformity }}</span
        >
        <span class="tooltiptext widetooltip"
          >Click to see all PPE nonconformity alerts for this month</span
        >
      </div>
      <div class="has-text-underlined mb-2 tooltip">
        <span
          class="is-size-5 has-text-weight-bold has-text-primary"
          @click="goToAlerts('redzone')"
          style="cursor: pointer"
          >Red Zones: {{ monthlyData.redzone }}</span
        >
        <span class="tooltiptext widetooltip"
          >Click to see all Red Zone violation alerts for this month</span
        >
      </div>
      <div class="has-text-underlined mb-2 tooltip">
        <span
          class="is-size-5 has-text-weight-bold has-text-primary"
          @click="goToAlerts('fall')"
          style="cursor: pointer"
          >Fall: {{ monthlyData.fall }}</span
        >
        <span class="tooltiptext widetooltip"
          >Click to see all fall alerts for this month</span
        >
      </div>

      <div class="has-text-underlined mb-2 tooltip">
        <span
          class="is-size-5 has-text-weight-bold has-text-success"
          @click="goToAlerts('approved')"
          style="cursor: pointer"
          >Approved: {{ monthlyData.approved }}</span
        >
        <span class="tooltiptext widetooltip"
          >Click to see all approved and valid alerts for this month</span
        >
      </div>

      <div class="has-text-underlined mb-2 tooltip">
        <span
          class="is-size-5 has-text-weight-bold has-text-danger"
          @click="goToAlerts('disapproved')"
          style="cursor: pointer"
          >Disapproved: {{ monthlyData.disapproved }}</span
        >
        <span class="tooltiptext widetooltip"
          >Click to see all disapproved alerts for this month</span
        >
      </div>

      <div class="has-text-underlined mb-2 tooltip">
        <span
          class="is-size-5 has-text-weight-bold has-text-warning"
          @click="goToAlerts('unclassified')"
          style="cursor: pointer"
          >Unclassified: {{ monthlyData.unclassified }}</span
        >
        <span class="tooltiptext widetooltip"
          >Click to see all unclassified alerts for this month</span
        >
      </div>

      <div class="has-text-underlined mb-2" v-if="witsml_enable == '1'">
        <span
          class="is-size-5 has-text-weight-bold"
          @click="goToAlerts('witsml')"
          style="cursor: pointer"
          >WITSML Sent: {{ monthlyData.witsml_total }}</span
        >
      </div>
    </div>

    <div
      class="generate-csv tooltip"
      :disabled="$store.state.isLoading == true"
    >
      <button
        class="button mb-4 has-text-light has-custom-width is-medium is-responsive is-primary"
        @click="generateCSV()"
      >
        <span class="icon">
          <i class="fas fa-table-list"></i>
        </span>
        <span class="is-family-sans-serif">Download CSV</span>
        <span class="tooltiptext widetooltip"
          >Click to export this month's overview in .csv format</span
        >
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import notificator from "@/mixins/notificator";
import { MonthPickerInput } from "../../vue-month-picker/src";
export default {
  components: {
    MonthPickerInput,
  },
  props: {
    monthlyData: Object,
    csvButton: {
      type: Boolean,
      default: true,
    },
    isPast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileName: "",
      cardData: this.monthlyData,
      filter: {
        date_end: 2500916953418,
        valid: true,
        invalid: true,
        non_classified: true,
        date_start: 0,
        camera: {
          1: true,
          2: true,
          3: true,
          4: true,
          5: true,
          6: true,
          7: true,
          8: true,
          9: true,
          10: true,
        },
      },
      witsml_enable: process.env.VUE_APP_WITSML,
    };
  },
  mixins: [notificator],
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    generateCSV() {
      // Avisa se n tiver alertas no dia de hj ou no mes
      if (this.monthlyData.total == 0) {
        if (this.monthlyData.month == "Today") {
          this.notifier("There are no alerts today", "warning");
        } else {
          this.notifier("This month has no alerts", "warning");
        }
      } else {
        // Cria um json com mes, ano e manda pra api
        let json = {
          year: this.monthlyData.csv_year,
          month: this.monthlyData.csv_month,
        };
        console.log(json);
        this.notifier("Generating CSV file...", "info");
        axios
          .post("api/v2/reports/overview/generate/csv/", json)
          .then((response) => {
            console.log(response.data);
            this.fileName = response.data.name;

            // Parte que realiza o download. A api do back retorna um caminho pro arquivo no django
            // É usado esse caminho para fazer um GET no arquivo
            axios
              .get(response.data.path, { responseType: "blob" })
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/csv",
                });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = this.fileName;
                link.click();
                URL.revokeObjectURL(link.href);
                this.notifier("CSV is ready", "info");
              })
              .catch(console.error);
          })
          .catch((error) => {
            console.log(error);
            this.notifier("Something went wrong", "error");
          });
      }

      this.act = "DOWNLOADED CSV";
      const data = {
        user: localStorage.getItem("harpiaUser"),
        stamp: Date.now(),
        action: this.act,
      };
      axios.post("api/v2/analytics/log", data).catch((error) => {
        console.log(error);
        if (error.response) {
          for (const property in error.response.data) {
            // this.errors.push(`${property}: ${error.response.data[property]}`)
            this.notifier(
              `${property}: ${error.response.data[property]}`,
              "error"
            );
          }
        }
      });
    },
    refreshCard(date) {
      // Emite um evento para Resumo.vue, passando os novos dados q o alerta deve ter
      this.$store.commit("setIsLoading", true);
      axios
        .get(
          "api/v2/reports/overview/month/" +
            date.monthIndex +
            "-" +
            date.year +
            "/"
        )
        .then((response) => {
          this.$emit("update-month", response.data);
          this.$store.commit("setIsLoading", false);
        })
        .catch((err0r) => {
          console.log(error);
          this.notifier("Something went wrong", "error");
        });
    },
    goToAlerts(location) {
      this.$store.dispatch("setIsFromOverviewToTrue");
      if (location == "approved") {
        this.filter.nonconformity = true;
        this.filter.redzone = true;
        this.filter.fall = true;
        this.filter.valid = true;
        this.filter.invalid = false;
        this.filter.classified = true;
        this.filter.unclassified = true;
        this.filter.non_classified = false;
        this.filter.witsml = false;
      } else if (location == "disapproved") {
        this.filter.nonconformity = true;
        this.filter.redzone = true;
        this.filter.fall = true;
        this.filter.valid = false;
        this.filter.invalid = true;
        this.filter.non_classified = false;
        this.filter.witsml = false;
      } else if (location == "unclassified") {
        this.filter.nonconformity = true;
        this.filter.redzone = true;
        this.filter.fall = true;
        this.filter.valid = false;
        this.filter.invalid = false;
        this.filter.classified = true;
        this.filter.unclassified = true;
        this.filter.non_classified = true;
        this.filter.witsml = false;
      } else if (location == "nonconformity") {
        this.filter.nonconformity = true;
        this.filter.redzone = false;
        this.filter.fall = false;
        this.filter.valid = true;
        this.filter.invalid = true;
        this.filter.classified = true;
        this.filter.unclassified = true;
        this.filter.non_classified = true;
        this.filter.witsml = false;
      } else if (location == "redzone") {
        this.filter.nonconformity = false;
        this.filter.redzone = true;
        this.filter.fall = false;
        this.filter.valid = true;
        this.filter.invalid = true;
        this.filter.classified = true;
        this.filter.unclassified = true;
        this.filter.non_classified = true;
        this.filter.witsml = false;
      } else if (location == "fall") {
        this.filter.nonconformity = false;
        this.filter.redzone = false;
        this.filter.fall = true;
        this.filter.valid = true;
        this.filter.invalid = true;
        this.filter.classified = true;
        this.filter.unclassified = true;
        this.filter.non_classified = true;
        this.filter.witsml = false;
      } else if (location == "witsml") {
        this.filter.nonconformity = true;
        this.filter.redzone = true;
        this.filter.fall = true;
        this.filter.valid = true;
        this.filter.invalid = true;
        this.filter.classified = true;
        this.filter.unclassified = true;
        this.filter.non_classified = true;
        this.filter.witsml = true;
      }
      this.filter.date_start = this.monthlyData.stamp_start;
      this.filter.date_end = this.monthlyData.stamp_end;
      this.$store.commit("save_filter", this.filter);
      this.$router.push("/latest-alerts");
    },
  },
};
</script>
