<template>

    <div class="loginwrapper ">
    <!-- <div class="loginshowcase">
      <h1>Sample text here.</h1>
      <img src="../assets/media/images/loginsplash.jpg" alt="">
    </div> -->
    <div class="loginsidebar">
      <div class="has-background-primary">

        <div class="vertical-horizontal-center">
          
          <div class="loginform">
            <form @submit.prevent="submitForm" class="has-text-left">
              <figure class="image is-3by2 is-fullwidth">
                <img
                  class="has-ratio"
                  :src="require('@/assets/media/images/harpia_logo_alt.png')"
                />
                <div class="logindesc"> <br> </div>
              </figure>
              <div class="field">
                <p class="control">
                  <span class="has-text-white-bis is-size-6 loginlabel">Login</span>
                  <input
                    class="input loginform is-hovered has-background-primary is-medium has-text-white-bis"
                    type="text"
                    v-model="username"
                  />
                </p>
              </div>
              <div class="field">

                <span class="has-text-white-bis is-size-6 loginlabel">Password</span>
                
                <div class="pwwrapper is-hovered has-background-primary is-medium" >
                  
                  <input
                    class="input is-medium has-text-white-bis"
                    v-model="password"
                    :type="passwordVisible ? 'text' : 'password'"
                    @keydown="checkCapsLock"
                    @keypress="checkCapsLock"
                    @keyup="checkCapsLock"
                  />
                  <button 
                    v-if="passwordVisible"
                    class="pweye has-background-primary is-medium has-text-white-bis tooltip" 
                    type="button"
                    @click="togglePasswordVisibility">
                    <i class="far fa-eye-slash"></i>
                    <span class="tooltiptext smalltooltip">Hide password</span>
                  </button> 
                  <button 
                    v-else
                    class="pweye has-background-primary is-medium has-text-white-bis tooltip" 
                    type="button"
                    @click="togglePasswordVisibility">
                    <i class="far fa-eye"></i>
                    <span class="tooltiptext smalltooltip">Show password</span>
                  </button> 
                  
              </div>
              <div class="capslock">
                <div class='capslocktip' v-if="capsLockOn">Warning: Caps Lock is on.</div>
              </div>
              
                

              
                <div class="notification is-danger" v-if="errors.length">
                  <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                </div>

                <div class="field">
                  <br />
                  <div class="control has-text-centered">
                    <button
                      class="button has-text-primary has-custom-width is-medium is-responsive is-light"
                    
                    >
                      Login<span class="is-family-sans-serif"></span>
                    </button>
                  </div>

                </div>
              </div>
            </form>
            
          </div>
        </div>

        
      </div>
    </div>
    </div>
    

</template>

<style lang="scss" scoped>
@import "../styles/extrastyles_login.scss";

.has-custom-width {
  width: 75%;
  border-radius: 2vh;
}

.vertical-horizontal-center {
  height: 100vh;
  padding: 0;
  justify-content: center;
  align-items: center;
}
</style>
<script>
import axios from "axios";
import notificator from "../mixins/notificator";
import { useToast } from "vue-toastification";

export default {
  name: "LogIn",
  components: {},

  data() {
    return {
      username: "",
      password: "",
      errors: [],
      passwordVisible: false,
      
      capsLockOn: false,
    };
  },
  mounted() {
    document.title = "Log In | Harpia";
  },
  mixins: [notificator],
  setup() {
    const toast = useToast();
    return { toast };
  },

  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    playAudio(vol) {
      let audio = new Audio(require("../assets/sound/login_ok.wav"));
      audio.volume = vol;
      audio.play(0.05);
      return audio;
    },
    checkCapsLock(event) {
        if (event.getModifierState("CapsLock")) {
         this.capsLockOn = true;
       } else {
        this.capsLockOn = false;
      }
    },

    async submitForm() {
      try {
        const { auth_token } = await this.login(this.username, this.password);
        this.updateAuthorization(auth_token);

        this.notifier("Welcome", "success");
        this.playAudio(0.05);
        this.$router.push("/latest-alerts");
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("harpiaUser");
        this.notifier("Something went wrong. Please try again", "error");
      }
    },
    async login(username, password) {
      const formData = {
        username,
        password,
      };

      const response = await axios.post("/api/v1/token/login", formData);
      return response.data;
    },
    updateAuthorization(token) {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");

      this.$store.commit("setToken", token);
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      localStorage.setItem("token", token);
      localStorage.setItem("harpiaUser", this.username);

      this.$store.dispatch("getUserPermissions")
    },
  },
};
</script>