<template>
    <!-- Display a card with information about a redzone -->
    <div class="redzone p-2">
      <!-- Use a flex layout to align elements horizontally -->
      <div class="is-flex is-justify-content-flex-start is-align-items-center">
        <!-- Display a square with a color indicating the redzone status -->
        <div>
          <div class="square" :class="{'square-redzone-active': redzone.enabled == true}"></div>
        </div>
        <!-- Display the redzone name and status -->
        <div>
          <!-- If the redzone is enabled, display a message indicating that it is active -->
          <div v-if="redzone.enabled == true">
            <p class="has-text-left text-redzone-active has-text-weight-bold ml-3 is-size-7">Activated</p>
          </div>
          <!-- If the redzone is not enabled, display a message indicating that it is inactive -->
          <div v-else>
            <p class="has-text-left text-redzone-inactive has-text-weight-bold ml-3 is-size-7">Disabled</p>
          </div>
          <!-- Display the redzone name -->
          <div>
            <div class="is-uppercase is-size-custom has-text-left has-text-weight-bold ml-3">{{ redzone.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      redzone: {
        type: Object,
        required: true,
      },
    },
  };
  </script>
<style scoped>
.redzone {
  min-width: 150px;
  max-width: 150px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%)
}
.is-size-custom {
    font-size: 0.65rem;
}
.square {
  height: 1.5rem;
  width: 1.5rem;
  background-color: #D85E56;
}
.square-redzone-active {
  background-color: #52B761;
}
.text-redzone-inactive {
  color: #D85E56;
}
.text-redzone-active {
  color: #52B761;
}
</style>  