<template>
  <div class="vertical-horizontal-center" v-if="$store.state.isAuthenticated">
    <nav class="navbar is-primary" role="navigation">
      <div class="navbar-brand">
        <a
          class="navbar-item is-align-items-center is-justify-content-center tooltip"
          @click="openAbout"
          ><img src="@/assets/media/images/harpia_logo.png" />
          <span class="tooltiptext">About ALTAVE Harpia</span>
        </a>
        <a
          class="navbar-burger"
          :class="{ 'is-active': showMobileMenu }"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar-menu"
          @click="showMobileMenu = !showMobileMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        class="navbar-menu"
        id="navbar-menu"
        v-bind:class="{ 'is-active': showMobileMenu }"
      >
        <div class="navbar-start">
          <router-link
            to="/latest-alerts"
            @click="send_log('ALERTS')"
            class="navbar-item tooltip"
            data-title="test"
            :class="{ 'is-underlined': GetCurrentRouteName === 'LatestAlerts' }"
          >
            Alerts
            <span class="tooltiptext">View real-time alerts</span>
          </router-link>

          <router-link
            to="/cameras"
            @click="send_log('CAMERAS')"
            class="navbar-item tooltip"
            data-title="test"
            :class="{ 'is-underlined': GetCurrentRouteName === 'Cameras' }"
            >Cameras
            <span class="tooltiptext">View cameras</span>
          </router-link>
          <router-link
            to="/red-zones"
            @click="send_log('REDZONES')"
            class="navbar-item tooltip"
            data-title="test"
            :class="{ 'is-underlined': GetCurrentRouteName === 'RedZones' }"
            >Red Zones
            <span class="tooltiptext"
              >Define new or update existing Red Zones</span
            >
          </router-link>
          <router-link
            to="/overview"
            @click="send_log('OVERVIEW')"
            class="navbar-item tooltip"
            data-title="test"
            :class="{ 'is-underlined': GetCurrentRouteName === 'Overview' }"
            >Overview
            <span class="tooltiptext">View monthly summaries for alerts</span>
          </router-link>
          <router-link
            to="/dashboard"
            @click="send_log('DASHBOARD')"
            class="navbar-item tooltip"
            data-title="test"
            :class="{ 'is-underlined': GetCurrentRouteName === 'Dashboard' }"
            >Dashboard
            <span class="tooltiptext">Detailed dashboard for alerts</span>
          </router-link>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <alerts-timer />
          </div>

          <div class="navbar-item">
            <div
              class="mx-3 is-flex is-align-items-center is-justify-content-center"
            >
              <span class="mx-2 is-size-4">
                <i class="fas fa-user-circle"
              /></span>
              <span class="is-capitalized">{{ user }}</span>
            </div>
            <div
              class="buttons is-flex is-align-items-center is-justify-content-center"
            >
              <template v-if="iconAudio">
                <div
                  class="button is-primary tooltip"
                  data-title="Sound off"
                  @click="audioSwitch()"
                >
                  <i class="fas fa-volume-high" />
                  <span class="tooltiptext smallertooltip">Turn off sound</span>
                </div>
              </template>

              <template v-else>
                <div
                  class="button is-primary tooltip"
                  data-title="Sound on"
                  @click="audioSwitch()"
                >
                  <i class="fas fa-volume-xmark" />
                  <span class="tooltiptext smallertooltip">Turn on sound</span>
                </div>
              </template>
              <template v-if="$store.state.isAuthenticated">
                <button
                  @click="logout()"
                  class="button is-danger is-outlined tooltip"
                  data-title="Logout"
                >
                  <i class="fas fa-right-from-bracket" />
                  <span class="tooltiptext smallertooltip">Logout</span>
                </button>
              </template>
              <template v-else>
                <router-link
                  to="/log-in"
                  class="button is-success is-outlined"
                  data-title="Login"
                  ><i class="fas fa-right-to-bracket"
                /></router-link>
              </template>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div
      class="is-loading-bar has-text-centered"
      v-bind:class="{ 'is-loading': $store.state.isLoading }"
    >
      <div class="lds-dual-ring"></div>
    </div>
  </div>
  <ModalCard
    :showModal="showModal"
    @closeModal="showModal = false"
    v-if="showModal"
  >
    <!-- Content of the modal goes here -->
  </ModalCard>
</template>

<script>
import axios from "axios";
import AlertsTimer from "@/components/AlertsTimer.vue";
import ModalCard from "./HarpiaAboutModal.vue";

export default {
  name: "HarpiaBar",
  components: {
    AlertsTimer,
    ModalCard,
  },
  data() {
    return {
      showMobileMenu: false,
      user: localStorage.getItem("harpiaUser"),
      showModal: false,
      iconAudio: true,
    };
  },
  beforeCreate() {
    this.$store.dispatch("getUserPermissions");
  },
  computed: {
    audioIcon() {
      return this.$store.state.audio.is_on
        ? "fas fa-volume-high"
        : "fas fa-volume-xmark";
    },
    GetCurrentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    GetCurrentRouteName: {
      handler() {
        this.showMobileMenu = false;
      },
    },
  },
  methods: {
    async logout() {
      try {
        const data = {
          user: this.user,
          stamp: Date.now(),
          action: "LOGOUT",
        };
        axios.post("api/v2/analytics/log", data);
      } catch (error) {
        localStorage.clear();
        console.log(error);
      } finally {
        axios.defaults.headers.common["Authorization"] = "";
        this.$store.commit("removeToken");
        this.$store.dispatch("resetFilters");
        this.$router.push("/log-in");
        z;
      }
    },
    audioSwitch() {
      this.audio = this.$store.state.audio;
      this.audio.is_on = !this.audio.is_on;
      localStorage.setItem("audio", JSON.stringify(this.audio));
      localStorage.setItem("audio_on", this.audio.is_on);
      this.iconAudio = !this.iconAudio;
      this.$store.dispatch("playAudio");
    },
    openAbout() {
      this.showModal = true;
    },
    closeModal(close) {
      this.showModal = false;
    },
    async send_log(page) {
      const data = {
        user: this.user,
        stamp: Date.now(),
        action: "PAGE_" + page,
      };
      axios.post("api/v2/analytics/log", data).catch((error) => {
        if (error.response) {
          for (const property in error.response.data) {
            this.notifier(
              `${property}: ${error.response.data[property]}`,
              "error"
            );
          }
        }
      });
    },
  },
  created() {
    this.audio = this.$store.state.audio;
    this.iconAudio = this.audio.is_on;
  },
};
</script>
