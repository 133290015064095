<template>
  <div class="dropdown is-hoverable">
    <div class="dropdown-trigger">
      <button class="button mr-2 is-outlined is-responsive is-primary">
        <span class="icon">
          <i class="fas fa-video-camera"></i>
        </span>
        
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu5" role="menu">
      <div class="dropdown-content">
        <span class="filter-tooltip">Filter by camera</span>
        <p class="dropdown-item">
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="filter.cameraNone"
              @click="$emit('camera-filter-all')"
            />
            <code>None</code>
          </label>
        </p>
        <p class="dropdown-item" v-for="(camera, idx) in cameras" :key="idx">
          <label class="checkbox">
            <input type="checkbox" v-model="cameras[idx]" />
            <code> Camera {{ idx }}</code>
          </label>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CameraFilterDropdown",
  props: {
    cameras: {
      type: Object,
      required: true,
    },
    filter: {
      type: Object,
    },
  },
};
</script>
