<template>
  <div>
    <harpiaBar />
    <div class="pagebody">
      <div class="testingwatermark" v-if="TestMode">
        <h1>
          <i class="fa-solid fa-triangle-exclamation"></i>
          <br />
          System in Testing
        </h1>
      </div>

      <div class="resumo_page">
        <div class="hero is-fullheight-with-navbar mg-large">
          <div class="columns is-multiline is-centered hero-body">
            <overview-card
              class="column is-3 is-size-4 is-full-mobile "
              v-bind:monthly-data="pastMonth"
              @update-month="updateMonth"
              :is-past="true"
            />
            <overview-card
              class="column is-3 is-size-4 is-full-mobile"
              v-bind:monthly-data="thisMonth"
            />
            <overview-card
              class="column is-3 is-size-4 is-full-mobile"
              v-bind:monthly-data="today"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/extrastyles_overview.scss";
</style>
<script>
import HarpiaBar from "@/components/HarpiaBar.vue";
import OverviewCard from "@/components/OverviewCard.vue";
import axios from "axios";
import notificator from "../mixins/notificator";
import { useToast } from "vue-toastification";

export default {
  name: "Resumo",
  components: {
    HarpiaBar,
    OverviewCard,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      today: [],
      thisMonth: [],
      pastMonth: [],
      TestMode: "",
    };
  },
  mounted() {
    this.getAlerts();
    document.title = "Overview | Harpia";
    this.TestModeSet();
  },
  mixins: [notificator],
  methods: {
    TestModeSet() {
      var TestBool = process.env.VUE_APP_TestMode == "true";
      this.TestMode = Boolean(TestBool);
      //casts string from .env to boolean
    },
    /* Pega os dados do 'alerts/report' e salva em variáveis separadas, uma pra cada card */
    async getAlerts() {
      try {
        this.$store.commit("setIsLoading", true);
        const response = await axios.get("/api/v2/reports/overview/alerts");
        this.today = response.data.today;
        this.thisMonth = response.data.now;
        this.pastMonth = response.data.past;
      } catch (error) {
        this.notifier("Something went wrong. Please try again", "error");
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    updateMonth(data) {
      // Atualiza o primeiro card com os dados do mês selecionado
      this.pastMonth = data;
    },
  },
};
</script>

<style scoped></style>
