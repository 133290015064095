<template>
  <div class="tooltip">
    <button
      class="button is-outlined is-rounded is-medium is-inverted"
      @click="goToPage"
      :disabled="disableButton"
    >
      <i :class="btnIcon" />
      <span class="tooltiptext smallertooltip"
      v-if="this.btnAction == 'previousPage'"
      >
        Previous
      </span>
      <span class="tooltiptext smallertooltip"
      v-else
      >
        Next
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: "PaginationButton",
  props: {
    btnIcon: {
      type: String,
      default: "",
    },
    btnAction: {
      type: String,
    },
    page: {
      type: [Number, String],
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disableButton() {
      if (this.btnAction === "previousPage") {
        return this.page < 2;
      }
      return !this.hasNextPage;
    },
  },
  methods: {
    goToPage() {
      const nextPage =
        this.btnAction === "previousPage"
          ? parseInt(this.page) - 1
          : parseInt(this.page) + 1;
      this.$emit("goToPage", nextPage);
    },
  },
};
</script>
