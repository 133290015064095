<template>
  <div>
    <div class="modal is-active">
      <div class="modal-card">
        <header class="modal-card-head has-background-primary">
          <p class="modal-card-title">Redzone name</p>
          <button class="delete" @click="cancel" aria-label="close"></button>
        </header>

        <section class="modal-card-body">
          <input
            class="input is-focused"
            :value="newerRedzone"
            type="text"
            placeholder="Insert Redzone name"
            pattern="[A-Za-z0-9]+"
            v-on:keyup.enter="save()"
            @input="updateRedZoneNameFromSaveModalInput($event.target.value)"
          />
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            :disabled="newerRedzone == '' || checkName()"
            @click="save"
          >
            Save Redzone
          </button>

          <button class="button is-danger isRight" @click="cancel">
            Cancel
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaveModal.vue",

  props: {
    newRedzone: {
      type: String,
    },
    redzones: {
      type: Object,
    },
  },

  watch: {
    newRedzone: {
      handler(newName) {
        this.newerRedzone = newName;
      },
    },
  },
  data() {
    return {
      newerRedzone: this.newRedzone,
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },

    cancel() {
      this.$emit("cancel");
    },

    updateRedZoneNameFromSaveModalInput(newName) {
      this.$emit("updateRedZoneNameFromSaveModalInput", newName);
    },

    checkName() {
      // Função para pesquisar e listar todos os nomes de uma redzone
      // para ser utilizada na checagem de nomes repetidos
      const redzones_named = [];
      for (const rd of this.redzones.values()) {
        redzones_named.push(rd.name.toUpperCase());
      }
      return redzones_named.includes(this.newRedzone.toUpperCase());
    },
  },
};
</script>

<style scoped></style>
