<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('close-camera-modal')"></div>
    <div class="modal-content cameras-modal">
      <p class="image">
        <img :src="imageSrc" />
      </p>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close-camera-modal')"
    ></button>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.cameras-modal {
  width: 80vw !important;
}
</style>
