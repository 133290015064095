<template>
  <div>
    <table
      class="mt-4 table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
    >
      <thead>
        <tr class="has-background-white-ter">
          <th>User</th>
          <th>Total Interactions</th>
        </tr>
      </thead>
      <tbody v-for="user in totalUserInteractions" :key="user">
        <tr>
          <th class="is-capitalized">{{ user[0] }}</th>
          <th>{{ user[1] }}</th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    totalUserInteractions: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style></style>
