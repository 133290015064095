<template>
  <div ref="zone">
    <div class="is-flex is-align-items-flex-start mb-2">
      <div class="is-inline-block is-clickable" @click="toggleHidden()">
        <button class="arrow mr-2" :class="{ 'arrow-hidden': isHidden }">
          <i class="fas fa-chevron-down"></i>
        </button>
        <span class="mt-1 is-size-4 is-size-6-touch">Per zone</span>
      </div>
    </div>
    <div :class="{ hidden: isHidden }" class="data">
      <div class="is-flex is-justify-content-space-between mb-2">
        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
          <div class="is-flex is-flex-direction-column tooltip">
            <Datepicker
              locale="en-US"
              v-if="dateOptionSelected == 'Year'"
              v-model="year"
              yearPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Month'"
              v-model="month"
              monthPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Week'"
              v-model="week"
              weekPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Day'"
              v-model="day"
              dayPicker
              :clearable="false"
              :enableTimePicker="false"
            />
            <span class="tooltiptext">Select a specific date</span>
          </div>
        </div>
        <dashboard-buttons-controls
          :chartType="chartType"
          :dateOptionSelected="dateOptionSelected"
          @download-PDF="downloadPDF"
          @switch-chart-type="switchChartType"
          @set-chart-data-option="setChartDataOption"
          @set-selected-date-option="setSelectedDateOption"
        />
      </div>

      <div>
        <div
          class="is-loading-bar has-text-centered"
          v-bind:class="{ 'is-loading': this.isZoneLoading }"
        >
          <div class="lds-dual-ring"></div>
        </div>
        <dashboard-chart-bar
          v-if="chartType == 'Bar'"
          :chart-data="chartData"
          :chartOptions="chartOptions"
        />
        <dashboard-chart-line
          v-if="chartType == 'Line'"
          :chart-data="chartData"
          :chartOptions="chartOptions"
          :height="300"
        />
      </div>
      <hr />
      <div class="mb-5 has-text-centered">
        <span class="is-size-4">Total {{ totalAlerts }}</span>
      </div>
      <div class="columns">
        <div class="column has-text-centered">
          <div class="has-text-grey-darker">Drill flor</div>
          <div class="has-text-weight-bold">
            {{ totalDrillFlor }} ({{ percentDrillFlor.toFixed(2) }}%)
          </div>
          <progress
            class="progress is-drillFlor mb-1"
            :value="percentDrillFlor"
            max="100"
          ></progress>
        </div>
        <div class="column has-text-centered">
          <div class="has-text-grey-darker">Pipe deck</div>
          <div class="has-text-weight-bold">
            {{ totalPipeDeck }} ({{ percentPipeDeck.toFixed(2) }}%)
          </div>
          <progress
            class="progress is-pipeDeck mb-1"
            :value="percentPipeDeck"
            max="100"
          ></progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DashboardChartBar from "@/components/DashboardChartBar.vue";
import DashboardChartLine from "@/components/DashboardChartLine.vue";
import DashboardButtonsControls from "@/components/DashboardButtonsControls.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MixinDashboardTotalAlerts from "@/mixins/MixinDashboardTotalAlerts.js";
import MixinDashboard from "@/mixins/MixinDashboard.js";
import MixinDashboardDates from "@/mixins/MixinDashboardDates.js";
export default {
  name: "TotalAlertsPerZone",
  components: {
    DashboardChartBar,
    DashboardChartLine,
    DashboardButtonsControls,
    Datepicker,
  },
  mixins: [MixinDashboardTotalAlerts, MixinDashboard, MixinDashboardDates],
  data() {
    return {
      chartType: "Bar",
      year: new Date().getFullYear(),
      month: { month: new Date().getMonth(), year: new Date().getFullYear() },
      week: [],
      day: new Date().toLocaleDateString("en-US"),
      dateOptionSelected: "Month",
      chartData: { labels: [], datasets: [] },
      chartOptions: {},
      totalAlerts: 0,
      totalDrillFlor: 0,
      totalPipeDeck: 0,
      percentRiserDeck: 0,
      percentDrillFlor: 0,
      percentPipeDeck: 0,
      isZoneLoading: false,
      isHidden: false,
      dashboardTooltipType: "sum",
    };
  },
  watch: {
    dateOptionSelected: {
      handler() {
        this.setChartDataOption();
      },
    },
    year: {
      handler() {
        this.getChartDataYear();
      },
    },
    month: {
      handler() {
        this.getChartDataMonth();
      },
    },
    week: {
      handler() {
        this.getChartDataOptionWeek();
      },
    },
    day: {
      handler() {
        this.getChartDataOptionDay();
      },
    },
  },
  methods: {
    async getChartDataYear() {
      this.isZoneLoading = true;
      let drillFlor_arr = [];
      let pipeDeck_arr = [];

      await axios
        .get(`/api/v2/analytics/year/${this.year}`)
        .then((response) => {
          this.totalAlerts = response.data.total;
          const cameras = response.data.cameras;
          let months = [];
          Object.keys(cameras).forEach((key) => {
            months.push(this.monthsNames[key]);
          });

          Object.values(cameras).forEach((value) => {
            drillFlor_arr.push(value[4] + value[5]);
            pipeDeck_arr.push(value[0] + value[1] + value[2] + value[3]);
          });

          const yearData = {
            drillFlor: drillFlor_arr,
            pipeDeck: pipeDeck_arr,
          };

          this.setChartData(months, yearData);
          this.setPercentTotalPerClass(yearData);
        });
      this.isZoneLoading = false;
    },
    async getChartDataMonth() {
      this.isZoneLoading = true;
      let drillFlor_arr = [];
      let pipeDeck_arr = [];

      let dates = [];

      await axios
        .get(
          `/api/v2/analytics/month-day/${this.month.month + 1}-${
            this.month.year
          }/`
        )
        .then((response) => {
          this.totalAlerts = response.data.monthly.camera.reduce(this.sum, 0);
          response.data.daily.forEach((data) => {
            let date = data.date;
            const [year, month, day] = date.split("-");
            const dateFormat = [month, day, year].join("/");
            dates.unshift(dateFormat);

            drillFlor_arr.unshift(data.camera[4] + data.camera[5]);
            pipeDeck_arr.unshift(
              data.camera[0] + data.camera[1] + data.camera[2],
              data.camera[3]
            );
          });
        })
        .catch((error) => {
          console.log(error);
          this.isZoneLoading = false;
        });

      const mounthData = {
        drillFlor: drillFlor_arr,
        pipeDeck: pipeDeck_arr,
      };
      this.setChartData(dates, mounthData);
      this.setPercentTotalPerClass(mounthData);
      this.isZoneLoading = false;
    },
    async getChartDataOptionWeek() {
      this.isZoneLoading = true;
      let drillFlor_arr = [];
      let pipeDeck_arr = [];
      let dates_arr = this.getDatesInRange(this.week[0], this.week[1]);
      let total = 0;

      let dates = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      for (let date of dates_arr) {
        let day = new Date(date)
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
        await axios
          .get(`/api/v2/analytics/day/${day}/`)
          .then((response) => {
            total += response.data.camera.reduce(this.sum, 0);
            drillFlor_arr.push(
              response.data.camera[4] + response.data.camera[5]
            );
            pipeDeck_arr.push(
              response.data.camera[0] +
                response.data.camera[1] +
                response.data.camera[2] +
                response.data.camera[3]
            );
          })
          .catch((error) => {
            console.log(error);
            this.isZoneLoading = false;
          });
      }
      this.totalAlerts = total;

      const weekData = {
        drillFlor: drillFlor_arr,
        pipeDeck: pipeDeck_arr,
      };

      this.setChartData(dates, weekData);
      this.setPercentTotalPerClass(weekData);
      this.isZoneLoading = false;
    },
    async getChartDataOptionDay() {
      this.isZoneLoading = true;
      let day = new Date(this.day)
        .toLocaleDateString("pt-br")
        .replaceAll("/", "-");
      let drillFlor_arr = [];
      let pipeDeck_arr = [];
      let hours = [];

      for (var i = 0; i < 24; i++) {
        let hour = i;
        let suffix = "AM";
        if (i >= 12) {
          suffix = "PM";
          if (i > 12) {
            hour -= 12;
          }
        }

        hour = hour < 10 ? "0" + hour : hour;
        hours.push((hour == "00" ? "12" : hour) + ":00 " + suffix);
      }

      await axios
        .get(`/api/v2/analytics/day/${day}/`)
        .then((response) => {
          this.totalAlerts = response.data.camera.reduce(this.sum, 0);
          for (let hour of response.data.camera_hour) {
            drillFlor_arr.push(hour[4] + hour[5]);
            pipeDeck_arr.push(hour[0] + hour[1] + hour[2] + hour[3]);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isZoneLoading = false;
        });

      const dayData = {
        drillFlor: drillFlor_arr,
        pipeDeck: pipeDeck_arr,
      };

      this.setChartData(hours, dayData);
      this.setPercentTotalPerClass(dayData);
      this.isZoneLoading = false;
    },
    configureChartDatasets() {
      this.chartData.datasets = [
        {
          label: "Drill floor",
          backgroundColor: "#7c1158",
          pointBackgroundColor: "#7c1158",
          borderColor: "#7c1158",
          borderWidth: 2,
          data: [],
          tension: 0.3,
        },
        {
          label: "Pipe deck",
          backgroundColor: "#4421af",
          pointBackgroundColor: "#4421af",
          borderColor: "#4421af",
          borderWidth: 2,
          data: [],
          tension: 0.3,
        },
      ];
    },
    setChartData(dates, data) {
      this.chartData.datasets[0].data = data.drillFlor;
      this.chartData.datasets[1].data = data.pipeDeck;
      this.chartData.labels = dates;
    },
    setPercentTotalPerClass(data) {
      this.totalDrillFlor = data.drillFlor.reduce(this.sum, 0);
      this.totalPipeDeck = data.pipeDeck.reduce(this.sum, 0);

      this.percentDrillFlor = isNaN(
        (this.totalDrillFlor * 100) / this.totalAlerts
      )
        ? 0
        : (this.totalDrillFlor * 100) / this.totalAlerts;
      this.percentPipeDeck = isNaN(
        (this.totalPipeDeck * 100) / this.totalAlerts
      )
        ? 0
        : (this.totalPipeDeck * 100) / this.totalAlerts;
    },
    downloadPDF() {
      this.isClassLoading = true;
      this.notifier("Generating PDF file...", "info");
      this.generatePDF("zone").then(() => {
        this.notifier("PDF is ready", "success");
        this.isClassLoading = false;
      });
    },
  },
  created() {
    this.setChartDataOption();
    this.configureChartDatasets();
  },
};
</script>

<style scoped>
.tabs {
  overflow-x: hidden;
}
.active {
  color: #3426d7;
}
.btnIcon:hover {
  color: gray;
}
.btnIcon {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
</style>
