<template>
  <div>
    <div class="calendar" v-if="isRange">
      <Datepicker
        v-model="_date"
        autoApply
        :enableTimePicker="false"
        calendarCellClassName="dp-custom-cell"
        placeholder="Filter by date"
      />
    </div>
    <div class="calendar" v-else>
      <Datepicker
        v-model="_date"
        autoApply
        range
        :enableTimePicker="false"
        calendarCellClassName="dp-custom-cell"
        placeholder="Filter by date"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    date: {
      type: [String, Array, Date],
    },
    isRange: {
      type: Boolean,
    },
  },
  watch: {
    date: {
      handler(date) {
        if (date == "Filter by date") {
          this._date = date;
        }
      },
    },
    _date: {
      handler(date) {
        if (date != "Filter by date") {
          this.$emit("update-date", date);
        }
      },
    },
  },
  data() {
    return {
      _date: this.date,
    };
  },
};
</script>
