<template>
  <div>
    <harpia-bar />
    <div class="pagebody">
      <div class="testingwatermark" v-if="TestMode">
        <h1>
          <i class="fa-solid fa-triangle-exclamation"></i>
          <br />
          System in Testing
        </h1>
      </div>

      <div>
        <tabs :selected="selectedTab" @change="updateSelectedTab" />

        <camera-view v-if="selectedTab === '1'" :src="cameras[0].url" />

        <camera-view v-if="selectedTab === '2'" :src="cameras[1].url" />

        <camera-view v-if="selectedTab === '3'" :src="cameras[2].url" />

        <camera-view-group
          v-if="selectedTab === '4'"
          :srcs="[cameras[0].url, cameras[1].url, cameras[2].url]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import harpiaBar from "@/components/HarpiaBar.vue";
import Tabs from "@/components/CamerasTabs.vue";
import CameraViewGroup from "@/components/CamerasViewGroup.vue";
import CameraView from "@/components/CamerasView.vue";

export default {
  components: {
    harpiaBar,
    Tabs,
    CameraViewGroup,
    CameraView,
  },
  data() {
    return {
      selectedTab: null,
      cameras: [
        { name: "camera0", url: null },
        { name: "camera1", url: null },
        { name: "camera2", url: null },
      ],
      isMounted: true,
      TestMode: "",
      minDelayInMs: 2000,
      timeoutId: null,
    };
  },
  mounted() {
    this.TestModeSet();
  },
  created() {
    this.timeoutId = setTimeout(this.refreshPage, 600000); //10min
    this.initializeSelectTab();
    this.updateCamera();
    document.title = "Cameras | Harpia";
  },
  beforeUnmount() {
    this.isMounted = false;
    clearTimeout(this.timeoutId);
  },
  methods: {
    initializeSelectTab() {
      const localStorageSelectedTab =
        localStorage.getItem("selectedTabCameras");
      this.selectedTab = localStorageSelectedTab
        ? localStorageSelectedTab
        : "1";
    },
    TestModeSet() {
      var TestBool = process.env.VUE_APP_TestMode == "true";
      this.TestMode = Boolean(TestBool);
      //casts string from .env to boolean
    },
    updateSelectedTab(tab) {
      localStorage.setItem("selectedTabCameras", tab);
      this.selectedTab = tab;
    },
    async fetchImages() {
      const urls = [
        `${process.env.VUE_APP_NGINX_URL}/cameras_images/cam0.jpg`,
        `${process.env.VUE_APP_NGINX_URL}/cameras_images/cam1.jpg`,
        `${process.env.VUE_APP_NGINX_URL}/cameras_images/cam2.jpg`,
      ];

      const responses = await Promise.allSettled(
        urls.map((url) =>
          axios.get(url, {
            responseType: "blob",
            headers: {
              "Content-Type": "image/jpeg",
            },
            timeout: 3000,
          })
        )
      );

      const blobs = responses.map((response) => {
        if (response.status === "fulfilled") {
          return response.value.data;
        } else {
          const emptyBlob = new Blob([], { type: "image/jpeg" });
          return emptyBlob;
        }
      });

      return blobs;
    },
    createImageElements(urls) {
      const images = urls.map((url) => {
        const img = new Image();
        img.src = url;
        console.log(`Create image url: ${url}`);
        return img;
      });

      return images;
    },
    async updateCamera() {
      const initialTime = Date.now();
      try {
        const images = await this.fetchImages();
        const urls = images.map((image) => URL.createObjectURL(image));
        const imagesObjects = this.createImageElements(urls);

        const oldImageElements = [
          this.cameras[0].url,
          this.cameras[1].url,
          this.cameras[2].url,
        ];
        oldImageElements.forEach((imageElement) => {
            URL.revokeObjectURL(imageElement);
        });

        this.cameras[0].url = imagesObjects[0].src;
        this.cameras[1].url = imagesObjects[1].src;
        this.cameras[2].url = imagesObjects[2].src;
      } catch (error) {
        console.error(error);
      }
      if (this.isMounted) {
        const executionTime = Date.now() - initialTime;
        if (executionTime > this.minDelayInMs) {
          this.updateCamera();
        } else {
          await new Promise((resolve) =>
            setTimeout(
              () => resolve(this.updateCamera()),
              this.minDelayInMs - executionTime
            )
          );
        }
      }
    },
    refreshPage() {
      window.location.reload();
    },
  },
};
</script>
