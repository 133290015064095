<template>
  <div>
    <v-stage ref="stage" :config="stageConfig">
      <v-layer ref="layer">
        <v-image
          @click="handleMouseClick"
          :config="{
            image: redZoneImage,
            scaleX: scale,
            scaleY: scale,
          }"
        />
        <v-line
          :config="{
            fill: 'hsla(0, 100%, 50%, 0.5)',
            points: points,
            tension: 0,
            closed: true,
            stroke: 'black',
          }"
        />
        <v-circle
          @dragmove="updatePoly"
          v-for="item in anchors"
          :key="item.id"
          :config="{
            id: item.id,
            x: item.x,
            y: item.y,
            radius: 4,
            fill: 'red',
            stroke: 'black',
            draggable: true,
          }"
        />
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
export default {
  name: "RedZoneImage",
  props: {
    stageConfig: {
      type: Object,
    },
    redZoneImage: {
      type: Image,
    },
    points: {
      type: Array,
    },
    anchors: {
      type: Array,
    },
    scale: {
      type: Number,
    },
  },
  methods: {
    handleMouseClick() {
      const mousePos = this.$refs.stage.getNode().getPointerPosition();
      const image = this.$refs.layer.getNode();
      const x = mousePos.x * image.scaleX() + image.x();
      const y = mousePos.y * image.scaleY() + image.y();
      this.anchors.push({
        id: Math.round(Math.random() * 10000).toString(),
        x: x,
        y: y,
      });
      this.points.push(x);
      this.points.push(y);
    },
    updatePoly(event) {
      const mousePos = this.$refs.stage.getNode().getPointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const id = event.target.id();
      const item = this.anchors.find((i) => i.id === id);
      const index = this.anchors.indexOf(item);
      const width = this.stageConfig.width;
      const height = this.stageConfig.height;
      this.points[index * 2] = x;
      this.points[index * 2 + 1] = y;


      if (x > width - 2) {
        this.points[index * 2] = width - 2;
        this.dotPositioner(index, width - 2, y);
      } else if (y > this.stageConfig.height - 2) {
        this.points[index * 2 + 1] = this.stageConfig.height - 2;
        this.dotPositioner(index, x, height - 2);
      } else if (x < 2) {
        this.points[index * 2] = 2;
        this.dotPositioner(index, 2, y);
      } else if (y < 2) {
        this.points[index * 2 + 1] = 2;
        this.dotPositioner(index, x, 2);
      } else {
        this.points[index * 2] = x;
        this.points[index * 2 + 1] = y;
      }
    },
    dotPositioner(i, x, y) {
      this.anchors[i] = {
        id: Math.round(Math.random() * 10000).toString(),
        x: x,
        y: y,
      };
    },
  },
};
</script>

<style></style>
