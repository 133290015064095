import { useToast } from "vue-toastification";

const notificator={
    setup() {
        const toast = useToast();
        return { toast }
      },
      data() {
          return {
              toast: useToast(),
              options:{
                position: "bottom-center",
                timeout: 2016,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: false,
                icon: true,
                rtl: false
              }
          }
      }, 

      methods: {
        notifier(msg,type){
            switch (type) {
                case 'success':
                    this.toast.success(msg, this.options);
                    break;
                case 'error':
                    this.toast.error(msg, this.options);
                    break;
                case 'warning':
                    this.toast.warning(msg, this.options);
                    break;
                case 'info':
                    this.toast.info(msg, this.options);
                    break;
                default:
                    this.toast(msg,type, this.options);
                    break;
            }

    

},
},


}
export default notificator