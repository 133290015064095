<template>
  <div class="dropdown is-right is-hoverable alertfilter">
    <div class="dropdown-trigger">
      <button
        class="button mr-2 is-outlined is-responsive is-primary"
        aria-haspopup="true"
        aria-controls="dropdown-menu5"
        @click="dropActive = !dropActive"
      >
        <span class="icon">
          <i class="fas fa-thumbs-up"></i>
        </span>
      </button>
    </div>

    <div class="dropdown-menu alertfilter" id="dropdown-menu5" role="menu">
      <div class="dropdown-content">
        <span class="filter-tooltip">Filter by approval</span>
        <p class="dropdown-item">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.invalid" />
            <code>Disapproved Alerts</code>
          </label>
        </p>

        <p class="dropdown-item">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.valid" />
            <code class="has-text-success">Approved Alerts</code>
          </label>
        </p>

        <p class="dropdown-item">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.non_classified" />
            <code class="has-text-warning">Unclassified</code>
          </label>
        </p>
      </div>
    </div>
  </div>

  <div class="dropdown is-right is-hoverable">
    <div class="dropdown-trigger">
      <button
        class="button mr-2 is-outlined is-responsive is-primary"
        aria-haspopup="true"
        aria-controls="dropdown-menu5"
        @click="dropActive = !dropActive"
      >
        <span class="icon">
          <i class="fas fa-filter"></i>
        </span>
      </button>
    </div>

    <div class="dropdown-menu alertfilter" id="dropdown-menu5" role="menu">
      <div class="dropdown-content">
        <span class="filter-tooltip">Filter by alert type</span>

        <p class="dropdown-item">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.nonconformity" />
            <code class="has-text-link-dark">PPE</code>
          </label>
        </p>

        <p class="dropdown-item">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.redzone" />
            <code class="has-text-link-dark">Red Zone</code>
          </label>
        </p>

        <p 
        v-if="showFall"
        class="dropdown-item">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.fall" />
            <code class="has-text-link-dark">Fall</code>
          </label>
        </p>

        <p class="dropdown-item" v-if="witsml_enable=='1'">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.witsml" />
            <code class="has-text-link-dark">WITSML</code>
          </label>
        </p>
      </div>
    </div>
  </div>

  <div class="dropdown is-right is-hoverable alertfilter">
    <div class="dropdown-trigger">
      <button
        class="button mr-2 is-outlined is-responsive is-primary"
        aria-haspopup="true"
        aria-controls="dropdown-menu5"
        @click="dropActive = !dropActive"
      >
        <span class="icon">
          <i class="fas fa-check"></i>
        </span>
      </button>
    </div>

    <div class="dropdown-menu alertfilter" id="dropdown-menu5" role="menu">
      <div class="dropdown-content">
        <span class="filter-tooltip">Filter by validity</span>
        <p class="dropdown-item">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.classified" />
            <code class="has-text-success">Validated Alerts</code>
          </label>
        </p>

        <p class="dropdown-item">
          <label class="checkbox">
            <input type="checkbox" v-model="filter.unclassified" />
            <code class="has-text-warning">Unclassified Alerts</code>
          </label>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
    },
  },
  data() {
    return {
      showFall: "",
      witsml_enable: process.env.VUE_APP_WITSML,
    }
  },
  mounted() {
    this.GetShowFall();
  },
  methods: {
    GetShowFall() {
      var showFall = process.env.VUE_APP_ALERT_FALL == 'true';
      this.showFall = Boolean(showFall);
    },
  },
};

//Método temporário para esconder/mostrar alertas de queda. Idealmente, todos os alertas no array no env são ser usados condicionalmente, sem a necessidade de bools e methods separados para cada alerta, algo bem pouco eficiente.
</script>

<style></style>
