<template>
  <div v-if="TestMode && $store.state.permissions.restricted">
    <harpia-bar />

    <div
      class="pagebody hero has-background-grey-lighter is-fullheight-with-navbar testing bg2"
    >
      <h1>
        <i class="fa-solid fa-triangle-exclamation"></i>
        <br />
        System in Testing
      </h1>
    </div>
  </div>

  <div v-else>
    <div class="testingwatermark" v-if="TestMode">
      <h1>
        <i class="fa-solid fa-triangle-exclamation"></i>
        <br />
        System in Testing
      </h1>
    </div>

    <harpia-bar />
    <div
      class="pagebody hero has-background-grey-lighter is-fullheight-with-navbar bg2"
    >
      <div class="home mb-2">
        <div class="columns is-vcentered">
          <div class="column is-1 is-multiline is-mobile">
            <alerts-pagination-button
              btnIcon="fas fa-angles-left fa-2x"
              btnAction="previousPage"
              :page="page"
              @go-to-page="goToPage"
            />
          </div>

          <div class="column is-10">
            <div>
              <div class="has-addons has-text-left navibar">
                <div class="filterbtns">
                  <alerts-camera-filter-dropdown
                    :cameras="filter.camera"
                    :filter="filter"
                    @camera-filter-all="cameraFilterAll"
                  />

                  <!-- Dropdown do filtro de like/dislike-->
                  <alerts-type-filter-dropdown :filter="filter" />
                </div>

                <!-- Select type of calendar -->
                <div class="datefilters">
                  <div class="my-1">
                    <alerts-select-type-button
                      @select-type="selectType"
                      @select-type-id="selectTypeId"
                    />
                  </div>

                  <!-- Btn confirm -->
                  <div class="searchbar mx-1">
                    <alerts-calendar
                      v-if="!isIdSelected"
                      :date="date"
                      :isRange="isRange"
                      @update-date="updateDate"
                    />

                    <!-- Input for ID -->
                    <alerts-id-search
                      v-else
                      @alert-id="selectedId"
                      :alertId="alertId"
                    />
                    <alerts-search-buttons
                      :date="date"
                      :action="'findAlerts'"
                      :btnIcon="'fas fa-search'"
                      @find-alerts="findAlerts"
                    >
                      Confirm
                    </alerts-search-buttons>
                  </div>
                </div>

                <!-- Btn reset -->

                <!-- Dropdown do filtro de cameras -->

                <div class="resetbtn">
                  <alerts-search-buttons
                    :date="date"
                    :action="'refreshPage'"
                    :btnIcon="'fas fa-sync'"
                    @refresh-page="refreshPage"
                  >
                    Reset
                  </alerts-search-buttons>
                </div>
              </div>
            </div>

            <!-- Alerts cards -->
            <alerts-cards-group
              :latest_alerts="latest_alerts"
              :page="page"
              @set-timer="set_alerts_timer"
            />
          </div>
          <div class="column is-1 is-multiline is-mobile">
            <alerts-pagination-button
              btnIcon="fas fa-angles-right fa-2x"
              btnAction="nextPage"
              :page="page"
              :hasNextPage="has_next_page"
              @go-to-page="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles/extrastyles_alerts.scss";
</style>

<script>
import axios from "axios";
import "@vuepic/vue-datepicker/dist/main.css";
import { useToast } from "vue-toastification";
import ReconnectingEventSource from "reconnecting-eventsource";
import notificator from "../mixins/notificator";

// componentes customizados
import HarpiaBar from "@/components/HarpiaBar.vue";
import AlertsPaginationButton from "@/components/AlertsPaginationButton.vue";
import AlertsSelectTypeButton from "@/components/AlertsSelectTypeButton.vue";
import AlertsCameraFilterDropdown from "@/components/AlertsCameraFilterDropdown.vue";
import AlertsTypeFilterDropdown from "@/components/AlertsTypeFilterDropdown.vue";
import AlertsCalendar from "@/components/AlertsCalendar.vue";
import AlertsSearchButtons from "@/components/AlertsSearchButtons.vue";
import AlertsCardsGroup from "@/components/AlertsCardsGroup.vue";
import AlertsIdSearch from "@/components/AlertsIdSearch.vue";
import AlertsTimer from "@/components/AlertsTimer.vue";

export default {
  name: "Alerts",
  components: {
    HarpiaBar,
    AlertsPaginationButton,
    AlertsSelectTypeButton,
    AlertsCameraFilterDropdown,
    AlertsTypeFilterDropdown,
    AlertsCalendar,
    AlertsSearchButtons,
    AlertsCardsGroup,
    AlertsIdSearch,
    AlertsTimer,
  },
  mixins: [notificator],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      latest_alerts: [],
      page: "1",
      last_date: new Date(),
      isRange: true,
      has_next_page: false,
      CurrentPage: 1,
      filter: null,
      confirmDisable: true,
      placeHolder: "",
      dropActive: false,
      dropCameraActive: false,
      date: null,
      eventSource: null,
      isIdSelected: false,
      alertId: "",
      filters: {
        search: "",
        cameras: [],
        thumb_up: false,
        valid: false,
        date_added: "",
      },
      alertstimerdisplay: "",
      TestMode: "",
    };
  },
  beforeCreate() {
    this.$store.dispatch("getUserPermissions");
  },
  mounted() {
    this.startConnection();
    document.title = "Alerts | Harpia"; //  titulo do documento para diferenciar dos outros .vue
    this.getLatestAlerts();
    // this.getLatestAlertsv2();
    this.getLastUpdateTimer();
    this.TestModeSet();
  },
  created() {
    this.filter = this.$store.getters["getFilter"];
    this.isFromOverview ? console.log("é") : this.resetFilters();
  },
  beforeUnmount() {
    this.stopConnection();
    this.$store.dispatch("setIsFromOverviewToFalse");
  },
  computed: {
    isFromOverview() {
      return this.$store.getters["GetIsFromOverview"];
    },
    isAuthenticated() {
      return this.$store.getters["GetIsAuthenticated"];
    },
    alerts() {
      return this.$store.state.alerts.alerts;
    },
    camerasString() {
      return this.filters.cameras.join(",");
    },
  },
  watch: {
    isRange: {
      handler() {
        // para mostrar as datas selecionadas ou "filter by date" quando troca pra day/period
        if (this.filter.date_start === 0) {
          this.date = "Filter by date";
          this.placeHolder = this.date;
        } else if (
          this.isRange &&
          this.filter.date_end - this.filter.date_start !== 86399999
        ) {
          this.date = "Filter by date";
          this.placeHolder = this.date;
        } else if (
          !this.isRange &&
          this.filter.date_end - this.filter.date_start !== 86399999
        ) {
          let date_0 = new Date(this.filter.date_start);
          let date_1 = new Date(this.filter.date_end);
          this.date =
            date_0.getMonth() +
            1 +
            "/" +
            date_0.getDate() +
            "/" +
            date_0.getFullYear() +
            " - ";
          this.date =
            this.date +
            (date_1.getMonth() + 1) +
            "/" +
            date_1.getDate() +
            "/" +
            date_1.getFullYear();
          this.placeHolder = this.date;
        } else if (
          !this.isRange &&
          this.filter.date_end - this.filter.date_start === 86399999
        ) {
          this.date = "Filter by date";
          this.placeHolder = this.date;
        } else if (
          this.isRange &&
          this.filter.date_end - this.filter.date_start === 86399999
        ) {
          this.date = new Date(this.filter.date_start);
          this.placeHolder = this.date;
        }
      },
    },
    filter: {
      handler() {
        // para desativar o checkbox do NONE caso selecione uma camera logo apos usa-lo
        for (const camera in this.filter.camera) {
          if (this.filter.camera[camera]) {
            this.filter.cameraNone = false;
          }
        }
        console.log(this.filter);
        this.getLatestAlerts();
      },
      deep: true,
    },
    filters: {
      deep: true,
      handler() {
        this.getLatestAlertsv2();
      },
    },
  },

  methods: {
    TestModeSet() {
      var TestBool = process.env.VUE_APP_TestMode == "true";
      this.TestMode = Boolean(TestBool);

      //casts string from .env to boolean
    },

    set_alerts_timer(timer) {
      this.alertstimerdisplay = timer;
    },
    getLastUpdateTimer() {
      axios
        .get("api/v2/last-update/")
        .then((response) => {
          console.log(response.data);
          this.alertstimerdisplay = response.data[0].time;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // playAudio() {
    //   if (this.$store.state.audio.is_on == true) {
    //     const audio = new Audio(require("../assets/sound/beep.wav"));
    //     if (audio.paused) {
    //       audio.play();
    //     }
    //   }
    // },
    async getLatestAlerts() {
      try {
        const response = await axios.post(
          "/api/v2/latest-alerts/" + this.page,
          this.filter
        );
        this.latest_alerts = response.data;
        if (Object.keys(this.latest_alerts).length > 6) {
          this.has_next_page = true; // usado para paginacao
          this.latest_alerts = this.latest_alerts.slice(0, 6);
        } else if (Object.keys(this.latest_alerts).length == 0) {
          if (this.page > 1) {
            this.has_next_page = false;
            this.page = this.page - 1;
            this.getLatestAlerts();
          }
        } else {
          this.has_next_page = false;
        }
      } catch (error) {
        if (this.isAuthenticated)
          this.notifier("Something went wrong. Please try again", "error");
      }
    },
    getLatestAlertsv2() {
      this.$store.dispatch("alerts/updateFilters", {
        ...this.filters,
        cameras: this.camerasString,
      });
    },
    goToPage(next_page) {
      this.page = next_page;
      this.getLatestAlerts();
    },
    refreshPage() {
      this.$store.dispatch("getUserPermissions");
      this.resetFilters();
      this.page = "1";
      this.getLatestAlerts();
    },
    findAlerts() {
      if (this.isIdSelected) {
        this.findAlertById();
      } else {
        if (this.isRange) {
          //Extrai apenas a data
          let day = new Date(this.date).toDateString();
          //Gera o timestamp da data as 00:00:00 horas
          let timestamp = new Date(day).getTime();

          // timestamp incluso no filtro
          this.filter.date_start = timestamp;
          this.filter.date_end = new Date(timestamp).setHours(23, 59, 59, 999);
          this.$store.commit("save_filter", this.filter);
        } else {
          //Extrai apenas a data
          let day0 = new Date(this.date[0]).toDateString();
          let day1 = new Date(this.date[1]).toDateString();
          //Gera o timestamp da data as 00:00:00 horas
          let timestamp0 = new Date(day0).getTime();
          let timestamp1 = new Date(
            new Date(day1).setHours(23, 59, 59, 999)
          ).getTime();

          // timestamp incluso no filtro
          this.filter.date_start = timestamp0;
          this.filter.date_end = timestamp1;
          this.$store.commit("save_filter", this.filter);
        }
        this.page = "1";
        this.getLatestAlerts();
      }
    },
    async findAlertById() {
      try {
        const response = await axios.get("/api/v2/alerts/" + this.alertId);
        this.latest_alerts = response.data;
        this.has_next_page = false;
        this.page = "1";
      } catch (error) {
        if (this.isAuthenticated)
          this.notifier("Something went wrong. Please try again", "error");
      }
    },
    resetFilters() {
      this.alertId = ""; //tentativa de resetar o input
      this.$store.dispatch("resetFilters");
      this.$store.dispatch(" getUserPermissions");
      this.date = "Filter by date";
      this.placeHolder = this.date;
    },
    cameraFilterAll() {
      if (!this.filter.cameraNone) {
        this.$store.dispatch("setAllCamerasToFalse");
      } else {
        this.$store.dispatch("setAllCamerasToTrue");
      }

      this.getLatestAlerts();
    },
    selectType(type) {
      this.isRange = type;
    },
    selectTypeId(isSelected) {
      this.isIdSelected = isSelected;
      if (isSelected == false) {
        this.date = "Filter by date";
        this.alertId = "";
      }
    },
    selectedId(id) {
      this.alertId = id;
      this.date = "a";
    },
    updateDate(date) {
      this.date = date;
    },
    createEventSource() {
      const url = process.env.VUE_APP_EVENTS_URL;
      const eventSource = new ReconnectingEventSource(url);
      eventSource.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.text == "update time") {
          this.getLastUpdateTimer();
        }
        if (message.text == "update alert") {
          this.getLatestAlerts();
        } else if (
          message.text == "new alert" &&
          this.$store.state.permissions.unlimited
        ) {
          this.$store.dispatch("playAudio");
          this.getLatestAlerts();
          this.notifier("New alert", "success");
        } else if (message.text == "new validated alert") {
          this.getLatestAlerts();
          if (this.$store.state.permissions.restricted) {
            this.$store.dispatch("playAudio");
            this.notifier("New validated alert", "success");
          }
        }
      };
      return eventSource;
    },
    startConnection() {
      this.eventSource = this.createEventSource();
    },
    stopConnection() {
      if (this.eventSource) {
        this.eventSource.close();
        this.eventSource = null;
      }
    },
  },
};
</script>
