<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head has-background-primary has-text-white">
          <i class="fas fa-info-circle"></i>
          <p class="modal-card-title">About ALTAVE Harpia</p>
          <button class="delete" @click="close" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column is-one-third abt-img">
              <img src="../assets/media/images/LogoColorful.png" alt="" />
            </div>

            <div class="column is-two-thirds">
              <p class="abt-info has-text-justified">
                <span class="abt-topic is-size-4 has-text-dark">System</span>
                <span class="abt is-size-5 has-text-weight-light has-text-grey-dark mt-0 ">{{ ShipName }}</span>
                <br />
                <span class="abt-topic is-size-4 has-text-dark">System Version</span>
                <span class="abt is-size-5 has-text-weight-light has-text-grey-dark mt-0 ">{{ SysVersion }}</span> 
                <br />
                <span class="abt-topic is-size-4 has-text-dark">Sauron Version</span>
                <span class="abt is-size-5 has-text-weight-light has-text-grey-dark mt-0 "> {{ SauVersion}}</span>
              </p>
            </div>
          </div>
        </section>

        <footer class="modal-card-foot">
          <button class="button is-info is-outlined">User Manual</button>
          <button class="button is-info is-outlined">ALTAVE Website</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>



.modal-card-body {
    overflow-x: hidden;
}

.modal-card-foot {
    align-items: center;
    justify-content: center;
  }

.columns {
    padding: 15px;
}
.abt-info {
    margin-left: 25px;
    }

.abt-img {
    padding: 15px;
}

.abt-topic {
    display: block;
}
</style>

<script>
export default {
  data() {
    return {
      ShipName: "",
      SysVersion: "",
      SauVersion: "",
    };
  },
  methods: {
    close() {
      this.$emit("closeModal", false);
    },
  },
};
</script>
