<template>
  <div v-if="loading">
    <!-- Show loading indicator or placeholder content -->
    Loading...
  </div>

  <div
    v-else
    class="is-flex is-align-items-center is-justify-content-center timer"
  >
    Last update: {{ alertstimerdisplay }}

    <button
      v-if="!$store.state.permissions.restricted"
      class="button is-primary is-inverted tooltip refreshing"
      @click="timestamp"
    >
      Refresh updates
      <span class="tooltiptext smallertooltip">Refresh updates</span>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import ReconnectingEventSource from "reconnecting-eventsource";

export default {
  data() {
    return {
      loading: true,
      alertstimerdisplay: "",
    };
  },
  beforeCreate() {
    this.$store.dispatch("getUserPermissions").then(() => {
      this.loading = false;
    });
  },
  mounted() {
    this.startConnection();
    this.getLastUpdateTimer();
  },
  beforeUnmount() {
    this.stopConnection();
  },
  methods: {
    timestamp() {
      if (!this.$store.state.permissions.restricted) {
        this.alertstimerdisplay = new Date();
        //`Current time - ${currentHour}:${currentMinute}`
        this.alertstimerdisplay = `
          ${String(this.alertstimerdisplay.getMonth() + 1).padStart(
            2,
            "0"
          )}/${String(this.alertstimerdisplay.getDate()).padStart(
          2,
          "0"
        )}/${String(this.alertstimerdisplay.getFullYear())} at
          ${String(this.alertstimerdisplay.getHours()).padStart(
            2,
            "0"
          )}:${String(this.alertstimerdisplay.getMinutes()).padStart(2, "0")}
        `;
        this.$emit("set-timer", this.alertstimerdisplay);

        let data = { time: this.alertstimerdisplay };
        axios
          .post("api/v1/last-update", data)
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getLastUpdateTimer() {
      axios
        .get("api/v1/last-update")
        .then((response) => {
          this.alertstimerdisplay = response.data[0].time;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startConnection() {
      this.eventSource = this.createEventSource();
    },
    stopConnection() {
      if (this.eventSource) {
        this.eventSource.close();
        this.eventSource = null;
      }
    },
    createEventSource() {
      const url = process.env.VUE_APP_EVENTS_URL;
      const eventSource = new ReconnectingEventSource(url);
      eventSource.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.text == "update time") {
          this.getLastUpdateTimer();
        }
      };
      return eventSource;
    },
  },
};
</script>
