import { S3 } from "aws-sdk";

const state = () => ({
  s3: null,
  awsBucketName: process.env.VUE_APP_AWS_S3_STORAGE_BUCKET_NAME,
  awsRegion: process.env.VUE_APP_AWS_S3_STORAGE_REGION,
  tenantName: process.env.VUE_APP_TENANT,
  shipName: process.env.VUE_APP_SHIP,
});

const mutations = {
  SET_S3(state, s3) {
    state.s3 = s3;
  },
};

const actions = {
  initializeS3({ commit, state }) {
    if (!state.s3) {
      const s3 = new S3({
        accessKeyId: process.env.VUE_APP_AWS_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_AWS_S3_SECRET_ACCESS_KEY,
        region: state.awsRegion,
      });
      commit("SET_S3", s3)
    }
  },
  getImageFromS3Bucket({ state }, imagePath) {
    const params = {
      Bucket: state.awsBucketName,
      Key: imagePath,
      Expires: 30,
    };

    try {
      const signedUrl = state.s3.getSignedUrl("getObject", params);
      return signedUrl;
    } catch (error) {
      console.error("Error fetching image from S3:", error);
      return "";
    }
  },
  getAlertImageFromS3Bucket({ state, dispatch }, payload) {
    const { isCleanImg, alertObj } = payload;
    const { identificador, timestamp } = alertObj;

    const date = new Date(timestamp);
    const FormattedDate = date.toLocaleDateString("pt-BR").replaceAll("/", "-");

    let imagePath = `${state.tenantName}/${state.shipName}/${FormattedDate}/${identificador}`;
    if (isCleanImg) imagePath += "_clean";
    imagePath += ".jpg";

    const imgUrl = dispatch("getImageFromS3Bucket", imagePath);
    return imgUrl;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
