<template>
  <div>
    <p class="subtitle mt-4">Select a camera:</p>
    <div class="buttons is-flex">
      <button
        v-for="(_, camera) in camerasTotal"
        :key="camera"
        class="button is-primary is-outlined is-small"
        :class="{ 'is-hovered': selectedCamera == `cam${camera}` }"
        @click="updateSelectedCamera(camera)"
      >
        Camera {{ camera + 1 }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CamerasSelectButton",
  props: {
    selectedCamera: {
      type: String,
    },
  },
  computed: {
    camerasTotal() {
      return this.$store.getters["GetCamerasTotal"];
    },
  },
  methods: {
    updateSelectedCamera(camera) {
      let newCamera = `cam${camera}`;
      this.$emit("updateSelectedCamera", newCamera);
    },
  },
};
</script>

<style scoped>
.button {
  min-width: 83.96px;
}
</style>
