const MixinDashboardTotalAlerts = {
  methods: {
    switchChartType(type) {
      this.chartType = type;
    },
    setChartDataOption() {
      if (this.dateOptionSelected == "Month") {
        this.getChartDataMonth();
      } else if (this.dateOptionSelected == "Day") {
        this.getChartDataOptionDay();
      } else if (this.dateOptionSelected == "Week") {
        this.getChartDataOptionWeek();
      } else if (this.dateOptionSelected == "Year") {
        this.getChartDataYear();
      }
    },
    configureChartOptions() {
      const tooltip = this.getTooltip(this.dashboardTooltipType);
      this.chartOptions = {
        fill: true,
        backgroundColor: "#ccc",
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: true,
              lineWidth: 3,
            },
            ticks: {
              display: true,
            },
          },
          y: {
            grid: {
              display: true,
            },
            ticks: {
              display: true,
            },
          },
        },
        interaction: {
          mode: "index",
          axis: "x",
          intersect: false,
        },
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            ...tooltip,
          },
        },
      };
    },
    downloadImage(chart) {
      const canvas = document
        .getElementById(chart)
        .firstChild.toDataURL("image/png");
      let link = document.createElement("a");
      link.download = "image1";
      link.href = canvas;
      link.click();
    },
    
    getTooltip(dashboardTooltipType) {
      const tooltips = {
        sum: {
          callbacks: {
            title: function () {},
            footer: (tooltipItems, data) => {
              let total = 0;

              tooltipItems.forEach(function (tooltipItem) {
                total += tooltipItem.parsed.y;
              });
              return "Total " + total;
            },
          },
        },
        percent: {
          callbacks: {
            title: function () {},
            label: function (tooltipItem) {
              const name = tooltipItem.dataset.label
              const value = tooltipItem.parsed.y;
              return `${name}: ${value}%`;
            },
            footer: (tooltipItems, data) => {
              let formattedAverage = null;
              tooltipItems.forEach(function (tooltipItem) {
                const total = tooltipItems.reduce(
                  (sum, tooltipItem) => sum + tooltipItem.parsed.y,
                  0
                );
                const average = total / tooltipItems.length;
                formattedAverage = average.toFixed(2);
              });
              return `Average percentage ${formattedAverage}%`;
            },
          },
        },
      };

      return tooltips[dashboardTooltipType];
    },
  },
  created() {
    this.configureChartOptions();
  },
};
export default MixinDashboardTotalAlerts;
