<template>
  <div ref="users">
    <div class="is-flex is-flex-direction-row is-align-items-flex-start mb-2">
      <div class="is-flex is-align-items-flex-start mb-2">
        <div class="is-inline-block is-clickable" @click="toggleHidden()">
          <button class="arrow mr-2" :class="{ 'arrow-hidden': isHidden }">
            <i class="fas fa-chevron-down"></i>
          </button>
          <span class="mt-1 is-size-4 is-size-6-touch">Users interactions</span>
        </div>
      </div>
    </div>
    <div :class="{ hidden: isHidden }" class="data">
      <div class="is-flex is-justify-content-space-between mb-2">
        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
          <div class="is-flex is-flex-direction-column tooltip">
            <Datepicker
              locale="en-US"
              v-if="dateOptionSelected == 'Year'"
              v-model="year"
              yearPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-if="dateOptionSelected == 'Month'"
              v-model="month"
              monthPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Week'"
              v-model="week"
              weekPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Day'"
              v-model="day"
              dayPicker
              :clearable="false"
              :enableTimePicker="false"
            />
            <span class="tooltiptext">Select a specific date</span>
          </div>
        </div>
        <div>
          <div class="is-flex is-justify-content-flex-end">
            <div class="ml-auto" :class="{ 'btn-hidden': isHidden }">
              <button class="icon btnIcon tooltip" @click="downloadPDF">
                <i class="fas fa-file-pdf"></i>
                <span class="tooltiptext">Download PDF</span>
              </button>
              <button class="icon btnIcon ml-auto tooltip" @click="getUsers()">
                <i class="fa-solid fa-arrows-rotate"></i>
                <span class="tooltiptext">Refresh</span>
              </button>
            </div>
          </div>
          <div class="tabs is-right">
            <ul>
              <li
                v-for="dateOption in dateOptions"
                :key="dateOption"
                :class="{ 'is-active': dateOption == dateOptionSelected }"
              >
                <a @click="setSelectedDateOption(dateOption)">{{
                  dateOption
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="is-loading-bar has-text-centered"
        :class="{ 'is-loading': isUsersLoading }"
      >
        <div class="lds-dual-ring"></div>
      </div>
      <dashboard-users-table
        :totalUserInteractions="totalUserInteractionsOrdered"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MixinDashboard from "@/mixins/MixinDashboard.js";
import MixinDashboardDates from "@/mixins/MixinDashboardDates.js";
import DashboardUsersTable from "@/components/DashboardUsersTable.vue";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  name: "Users",
  components: {
    DashboardUsersTable,
    Datepicker,
  },
  mixins: [MixinDashboard, MixinDashboardDates],
  watch: {
    dateOptionSelected: {
      handler() {
        this.getUsers();
      },
    },
    year: {
      handler() {
        this.getUsers();
      },
    },
    month: {
      handler() {
        this.getUsers();
      },
    },
    week: {
      handler() {
        this.getUsers();
      },
    },
    day: {
      handler() {
        this.getUsers();
      },
    },
  },
  data() {
    return {
      isHidden: false,
      users: [],
      userInteractions: {},
      totalUserInteractions: {},
      totalUserInteractionsOrdered: {},
      isUsersLoading: false,
      year: new Date().getFullYear(),
      month: { month: new Date().getMonth(), year: new Date().getFullYear() },
      week: [],
      day: new Date().toLocaleDateString("en-US"),
      dateOptions: ["Day", "Week", "Month", "Year"],
      dateOptionSelected: "Month",
    };
  },
  methods: {
    async getUsers() {
      this.isUsersLoading = true;
      this.resetUserInteractions();

      const date = this.getDateObject();

      await axios
        .get(`/api/v2/analytics/get_log`, { params: date })
        .then((response) => {
          this.users = response.data;
          this.setUserInteractions();
        })
        .catch((error) => {
          this.isUsersLoading = false;
          console.log(error);
        });
    },
    setUserInteractions() {
      for (let user in this.users) {
        try {
          this.userInteractions[user] = Object.values(this.users[user].count);
          let total = this.userInteractions[user].reduce(this.sum, 0);
          this.totalUserInteractions[user] = total;
        } catch {
          continue;
        }
      }
      this.totalUserInteractionsOrdered = this.sortUserInteractions();
    },
    sortUserInteractions() {
      this.isUsersLoading = false;
      return Object.entries(this.totalUserInteractions).sort(
        (a, b) => b[1] - a[1]
      );
    },
    resetUserInteractions() {
      this.totalUserInteractionsOrdered = {};
      this.userInteractions = {};
      this.totalUserInteractions = {};
      this.totalUserInteractionsOrdered = {};
    },
    getDateObject() {
      let date = { dt_init: new Date(), dt_final: new Date() };
      let dateObject;
      if (this.dateOptionSelected == "Month") {
        dateObject = new Date(this.month.year, this.month.month);
        date.dt_init = dateObject
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
        dateObject = new Date(this.month.year, this.month.month + 1, 0);
        date.dt_final = dateObject
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
      } else if (this.dateOptionSelected == "Day") {
        dateObject = new Date(this.day);
        date.dt_init = dateObject
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
        date.dt_final = dateObject
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
      } else if (this.dateOptionSelected == "Week") {
        dateObject = new Date(this.week[0]);
        date.dt_init = dateObject
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
        dateObject = new Date(this.week[1]);
        date.dt_final = dateObject
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
      } else if (this.dateOptionSelected == "Year") {
        dateObject = new Date(`${this.year}-01-01T00:00:00Z`);
        date.dt_init = dateObject
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
        dateObject = new Date(`${this.year}-12-31T23:59:59Z`);
        date.dt_final = dateObject
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
      }
      return date;
    },
    downloadPDF() {
      this.isRedzonesLoading = true;
      this.notifier("Generating PDF file...", "info");
      this.generatePDF("users").then(() => {
        this.notifier("PDF is ready", "success");
        this.isRedzonesLoading = false;
      });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style scoped>
.btnIcon {
  justify-self: flex-end;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.btnIcon:hover {
  color: gray;
}
.teste {
  transform: rotate(90deg);
}
</style>
