import axios from "axios";
// Vuex store module for managing data for a widget that displays alerts for last four days in a month
// The store has a state object with a widgetsMonthDayAlerts array and a month object with the current month and year
// The store also has a number of getters that allow the consumption of data from the widgetsMonthDayAlerts array
// The store has an action, getWidgetMonthDayData, which makes an HTTP GET request to an API endpoint to retrieve data for the current month and year
// This data is then committed to the store using the setWidgetMonthDayData mutation
// The store has a mutation, setWidgetMonthDayData, which updates the widgetsMonthDayAlerts array in the store with the data received from the API

// state object containing widgetsMonthDayAlerts array and month object
const state = () => ({
  // array to hold alerts for last four days in the month
  widgetsMonthDayAlerts: [],
  // object to hold the current month and year
  month: { month: new Date().getMonth(), year: new Date().getFullYear() },
});

// getters to allow consumption of data from widgetsMonthDayAlerts array
const getters = {
  // returns array of dates for each alert in widgetsMonthDayAlerts
  getWidgetsMonthDayDates: (state) => {
    return state.widgetsMonthDayAlerts.map((alert) => {
      const [year, month, day] = alert.date.split("-");
      return [month, day, year].join("/");
    });
  },
  // returns array of redzone alerts for each alert in widgetsMonthDayAlerts
  getWidgetsMonthDayRedzonesAlerts: (state) => {
    return state.widgetsMonthDayAlerts.map((alert) => alert.redzone);
  },
  // returns array of nonconformity alerts for each alert in widgetsMonthDayAlerts
  getWidgetsMonthDayPPEsAlerts: (state) => {
    return state.widgetsMonthDayAlerts.map((alert) => alert.nonconformity);
  },
  // returns array of total alerts for each alert in widgetsMonthDayAlerts
  getWidgetsMonthDayTodayAlerts: (state) => {
    return state.widgetsMonthDayAlerts.map((alert) => alert.total);
  },
};

// actions to retrieve data from API and commit data to store
const actions = {
  // action to retrieve data for current month and year from API
  async getWidgetMonthDayData({ commit, state }) {
    let widgetsMonthDayAlerts = [];
    // make HTTP GET request to API and retrieve data for current month and year
    await axios
      .get(
        `/api/v2/reports/month-day/${state.month.month + 1}-${state.month.year}`
      )
      .then((response) => {
        response.data.daily.forEach((data) => {
          // unshift data onto widgetsMonthDayAlerts array
          widgetsMonthDayAlerts.unshift(data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // slice the last 4 elements from the widgetsMonthDayAlerts array and commit to the store
    commit("setWidgetMonthDayData", widgetsMonthDayAlerts.slice(-4));
  },
};

// mutations to update store with data from API
const mutations = {
  // mutation to update widgetsMonthDayAlerts array in store with data from API
  setWidgetMonthDayData(state, data) {
    state.widgetsMonthDayAlerts = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
