<template>
  <div class="is-flex is-justify-content-center">
    <dashboard-chart-line
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartId="'widgetLastTwoDays'"
    />
  </div>
</template>

<script>
import DashboardChartLine from "@/components/DashboardChartLine.vue";
import MixinDashboardWidget from "@/mixins/MixinDashboardWidget.js";

export default {
  name: "DashboardWidgetTodayAlerts",
  components: {
    DashboardChartLine,
  },
  props: {
    dates: {
      type: Array,
      require: true,
    },
    todayAlerts: {
      type: Array,
      require: true,
    },
  },
  mixins: [MixinDashboardWidget],
  data() {
    return {
      month: { month: new Date().getMonth(), year: new Date().getFullYear() },
      chartData: {
        labels: ["April", "May", "June", "July"],
        datasets: [
          {
            label: "Total alerts",
            pointBackgroundColor: "#3426D7",
            borderColor: "rgba(255, 255, 255, 0.5)",
            borderWidth: 1,
            data: [28, 21, 16, 36],
            tension: 0.2,
            showGrid: false,
          },
        ],
      },
    };
  },
  mounted() {
    this.setTodayData();
    this.setChartTitle(this.todayAlerts);
    this.chartOptions.plugins.title.text[1] = "Total alerts";
  },
  methods: {
    async setTodayData() {
      this.chartData.labels = this.dates
      this.chartData.datasets[0].data = this.todayAlerts
    },
  },
};
</script>
