<template>
  <div ref="camera">
    <div class="is-flex is-align-items-flex-start mb-2">
      <div class="is-inline-block is-clickable" @click="toggleHidden()">
        <button class="arrow mr-2" :class="{ 'arrow-hidden': isHidden }">
          <i class="fas fa-chevron-down"></i>
        </button>
        <span class="mt-1 is-size-4 is-size-6-touch">Per camera</span>
      </div>
    </div>
    <div :class="{ hidden: isHidden }" class="data">
      <div class="is-flex is-justify-content-space-between mb-2">
        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
          <div class="is-flex is-flex-direction-column tooltip">
            <Datepicker
              locale="en-US"
              v-if="dateOptionSelected == 'Year'"
              v-model="year"
              yearPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Month'"
              v-model="month"
              monthPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Week'"
              v-model="week"
              weekPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Day'"
              v-model="day"
              dayPicker
              :clearable="false"
              :enableTimePicker="false"
            />
            <span class="tooltiptext">Select a specific date</span>
          </div>
        </div>
        <dashboard-buttons-controls
          :chartType="chartType"
          :dateOptionSelected="dateOptionSelected"
          @download-PDF="downloadPDF"
          @switch-chart-type="switchChartType"
          @set-chart-data-option="setChartDataOption"
          @set-selected-date-option="setSelectedDateOption"
        />
      </div>

      <div>
        <div
          class="is-loading-bar has-text-centered"
          v-bind:class="{ 'is-loading': this.isCameraLoading }"
        >
          <div class="lds-dual-ring"></div>
        </div>
        <dashboard-chart-bar
          v-if="chartType == 'Bar'"
          :chart-data="chartData"
          :chartOptions="chartOptions"
        />
        <dashboard-chart-line
          v-if="chartType == 'Line'"
          :chart-data="chartData"
          :chartOptions="chartOptions"
          :height="300"
        />
      </div>
      <hr />
      <div class="mb-5 has-text-centered">
        <span class="is-size-4">Total {{ totalAlerts }}</span>
      </div>
      <div class="columns">
        <div
          v-for="(camera, index) in camerasTotal"
          :key="index"
          class="column has-text-centered"
        >
          <div class="has-text-grey-darker">Camera {{ camera }}</div>
          <div class="has-text-weight-bold">
            {{ totalCams[index] }} ({{
              percentCams[index] !== undefined
                ? percentCams[index].toFixed(2)
                : "0"
            }}%)
          </div>
          <div class="progress-container">
            <div
              class="progress-bar"
              :class="`is-cam${camera}`"
              :style="{ width: percentCams[index] + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DashboardChartBar from "@/components/DashboardChartBar.vue";
import DashboardChartLine from "@/components/DashboardChartLine.vue";
import DashboardButtonsControls from "@/components/DashboardButtonsControls.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MixinDashboardTotalAlerts from "@/mixins/MixinDashboardTotalAlerts.js";
import MixinDashboard from "@/mixins/MixinDashboard.js";
import MixinDashboardDates from "@/mixins/MixinDashboardDates.js";

export default {
  name: "TotalAlertsPerCamera",
  components: {
    DashboardChartBar,
    DashboardChartLine,
    DashboardButtonsControls,
    Datepicker,
  },
  mixins: [MixinDashboardTotalAlerts, MixinDashboard, MixinDashboardDates],
  data() {
    return {
      chartType: "Bar",
      year: new Date().getFullYear(),
      month: { month: new Date().getMonth(), year: new Date().getFullYear() },
      week: [],
      day: new Date().toLocaleDateString("en-US"),
      dateOptionSelected: "Month",
      chartData: { labels: [], datasets: [] },
      chartOptions: {},
      totalAlerts: 0,
      percentCams: [],
      totalCams: [],
      isCameraLoading: false,
      isHidden: false,
      dashboardTooltipType: "sum",
    };
  },
  computed: {
    camerasTotal() {
      return this.$store.getters["GetCamerasTotal"];
    },
  },
  watch: {
    dateOptionSelected: {
      handler() {
        this.setChartDataOption();
      },
    },
    year: {
      handler() {
        this.getChartDataYear();
      },
    },
    month: {
      handler() {
        this.getChartDataMonth();
      },
    },
    week: {
      handler() {
        this.getChartDataOptionWeek();
      },
    },
    day: {
      handler() {
        this.getChartDataOptionDay();
      },
    },
  },
  methods: {
    createCameraArrays() {
      const cam_arr = [];

      for (let i = 1; i <= this.camerasTotal; i++) {
        cam_arr.push([]);
      }

      return cam_arr;
    },
    extractDataFromCameras(cameras) {
      const camArr = [];
      for (let i = 0; i < this.camerasTotal; i++) {
        camArr.push([]);
        Object.values(cameras).forEach((value) => {
          camArr[i].push(value[i]);
        });
      }
      return camArr;
    },
    extractDataFromDaily(dailyData) {
      const camArr = this.createCameraArrays();
      dailyData.forEach((data) => {
        for (let i = 0; i < this.camerasTotal; i++) {
          camArr[i].unshift(data.camera[i]);
        }
      });
      return camArr;
    },
    extractDataFromCameraHour(cameraHour) {
      const camArr = this.createCameraArrays();

      for (let hour of cameraHour) {
        for (let i = 0; i < this.camerasTotal; i++) {
          camArr[i].push(hour[i]);
        }
      }
      return camArr;
    },
    async getChartDataYear() {
      try {
        this.isCameraLoading = true;
        this.totalAlerts = 0;
        const response = await axios.get(`/api/v2/reports/year/${this.year}`);

        this.totalAlerts = response.data.total ?? 0;

        const cameras = response.data.cameras;
        let months = this._mixinDatesGetMonthsNamesFromData(cameras);

        const camArr = this.extractDataFromCameras(cameras);
        const yearData = this.createDataObject(camArr);

        this.setChartDataForCamerasAndSystemAvailability(months, yearData);
        this.setPercentTotalPerCam(yearData);
      } catch (error) {
        console.log(error);
      } finally {
        this.isCameraLoading = false;
      }
    },
    async getChartDataMonth() {
      this.isCameraLoading = true;
      try {
        const response = await axios.get(
          `/api/v2/reports/month-day/${this.month.month + 1}-${this.month.year}`
        );

        this.totalAlerts = response.data.monthly.camera.reduce(this.sum, 0);
        const dailyData = response.data.daily;

        const days = this._mixinDatesGetFormattedDatesPerMonth(dailyData);
        const camArr = this.extractDataFromDaily(dailyData);
        const monthData = this.createDataObject(camArr);

        this.setChartDataForCamerasAndSystemAvailability(days, monthData);
        this.setPercentTotalPerCam(monthData);
      } catch (error) {
        console.log(error);
      } finally {
        this.isCameraLoading = false;
      }
    },
    async getChartDataOptionWeek() {
      this.isCameraLoading = true;
      const cam_arr = this.createCameraArrays();
      let dates_arr = this.getDatesInRange(this.week[0], this.week[1]);
      let total = 0;
      try {
        for (let date of dates_arr) {
          let day = new Date(date)
            .toLocaleDateString("pt-br")
            .replaceAll("/", "-");

          const response = await axios.get(`/api/v2/reports/day/${day}`);
          total += response.data.camera.reduce(this.sum, 0);
          for (let i = 0; i < this.camerasTotal; i++) {
            cam_arr[i].push(response.data.camera[i]);
          }
        }
        const weekData = this.createDataObject(cam_arr);
        this.setChartDataForCamerasAndSystemAvailability(
          this._mixinDatesWeekDaysNames,
          weekData
        );
        this.totalAlerts = total;
        this.setPercentTotalPerCam(weekData);
      } catch (error) {
        console.log(error);
      } finally {
        this.isCameraLoading = false;
      }
    },
    async getChartDataOptionDay() {
      this.isCameraLoading = true;
      let day = new Date(this.day)
        .toLocaleDateString("pt-br")
        .replaceAll("/", "-");

      try {
        const response = await axios.get(`/api/v2/reports/day/${day}`);
        this.totalAlerts = response.data.camera.reduce(this.sum, 0);

        const camerHourData = response.data.camera_hour;
        const camArr = this.extractDataFromCameraHour(camerHourData);
        const dayData = this.createDataObject(camArr);
        this.setChartDataForCamerasAndSystemAvailability(this.hours, dayData);
        this.setPercentTotalPerCam(dayData);
      } catch (error) {
        console.log(error);
      } finally {
        this.isCameraLoading = false;
      }
    },
    configureChartDatasets() {
      this.chartData.datasets = [];
      for (let i = 1; i <= this.camerasTotal; i++) {
        const color = getComputedStyle(
          document.documentElement
        ).getPropertyValue(`--cam${i}`);
        this.chartData.datasets.push({
          label: `Camera ${i}`,
          backgroundColor: color,
          pointBackgroundColor: color,
          borderColor: color,
          borderWidth: 2,
          data: [],
          tension: 0.3,
        });
      }
    },
    setPercentTotalPerCam(data) {
      this.percentCams = [];
      this.totalCams = [];
      for (let i = 1; i <= this.camerasTotal; i++) {
        let key = `cam${i}`;

        let totalCam = data[key].reduce(this.sum, 0);
        this.totalCams.push(isNaN(totalCam) ? 0 : totalCam);

        let _percentCam = (totalCam * 100) / this.totalAlerts;
        this.percentCams.push(isNaN(_percentCam) ? 0 : _percentCam);
      }
    },
    downloadPDF() {
      this.isClassLoading = true;
      this.notifier("Generating PDF file...", "info");
      this.generatePDF("camera").then(() => {
        this.notifier("PDF is ready", "success");
        this.isClassLoading = false;
      });
    },
  },
  created() {
    this.setChartDataOption();
    this.configureChartDatasets();
  },
};
</script>
