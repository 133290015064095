<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :width="210"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
export default {
  name: "lineGraph",
  components: {
    LineChartGenerator,
  },
  props: {
    chartOptions: {
      type: Object,
    },
    chartData: {
      type: Object,
    },
    chartId: {
      type: String,
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    height: {
      type: Number,
      default: 100,
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
