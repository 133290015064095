<template>
  <div class="zoom-in">
    <div>
      <swiper
        ref="mySwiper"
        :slides-per-view="1"
        :modules="modules"
        :zoom="true"
        :allowTouchMove="false"
        :thumbs="{ swiper: thumbSwiperInstance }"
        @swiper="onSwiper"
        @click="toggleZoom"
        @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
        @slideChangeTransitionStart="onSlideChangeTransitionStart"
        effect="fade"
      >
        <swiper-slide>
          <figure class="swiper-zoom-container">
            <img class="swiper-slide__image" :src="alertImage" />
          </figure>
        </swiper-slide>
        <swiper-slide>
          <figure class="swiper-zoom-container">
            <img class="swiper-slide__image" :src="alertImageClean" />
          </figure>
        </swiper-slide>
      </swiper>

      <swiper
        class="gallery-thumbs"
        :modules="modules"
        :allowTouchMove="false"
        watch-slides-progress
        @swiper="onThumbsSwiper"
        @click="changeSlide"
        effect="fade"
      >
        <swiper-slide>
          <figure>
            <figcaption>Clean</figcaption>
            <img :src="alertImageClean" />
          </figure>
        </swiper-slide>
        <swiper-slide>
          <figure>
            <figcaption>Inference</figcaption>
            <img :src="alertImage" />
          </figure>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
// import required modules
import { Zoom, Thumbs, EffectFade } from "swiper";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper.scss";
import "swiper/modules/effect-fade/effect-fade.scss";
import "swiper/modules/zoom/zoom.scss";
import "swiper/modules/thumbs/thumbs.scss";

export default {
  name: "AlertsModalImages",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    alertImage: {
      type: String,
    },
    alertImageClean: {
      type: String,
    },
  },
  data() {
    return {
      modules: [Zoom, Thumbs, EffectFade],
      swiperInstance: null,
      thumbSwiperInstance: null,
      zoomState: null,
      isZoomed: false,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiperInstance = swiper;
    },
    onThumbsSwiper(swiper) {
      this.thumbSwiperInstance = swiper;
    },
    onSlideChangeTransitionStart() {
      console.log('start');
      if (this.isZoomed) this.swiperInstance.zoom.toggle(this.zoomState);
    },
    onSlideChangeTransitionEnd() {
      if (this.isZoomed) this.swiperInstance.zoom.toggle(this.zoomState);
    },
    toggleZoom(event) {
      if (this.swiperInstance) {
        this.isZoomed = !this.isZoomed;
        this.zoomState = event.touches;
        this.swiperInstance.zoom.toggle(event);
      }
    },
    changeSlide() {
      this.swiperInstance.isEnd
        ? this.swiperInstance.slidePrev()
        : this.swiperInstance.slideNext();
    },
  },
};
</script>
<style lang="scss">
.zoom-in {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.swiper-slide__image {
  width: 100%;
}

.image-modal-btn {
  position: absolute !important;
  top: 0px;
  right: 10px;
  z-index: 9999;
  max-height: 50px !important;
  max-width: 50px !important;
  height: 50px !important;
  width: 50px !important;
  width: 52px;
  height: 52px;
  color: white;
  background-color: rgba(10, 10, 10, 0.6) !important;
  border-radius: 50%;

  &::after {
    font-size: 2rem;
  }

  &:hover {
    background-color: rgba(10, 10, 10, 0.7) !important;
  }
}
.gallery-thumbs {
  cursor: pointer;
  position: absolute;
  bottom: 0.5vw;
  left: 0.5vw;
  width: 10%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 5px;
}
figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 14px;
  margin: 0;
  box-sizing: border-box;
}
</style>