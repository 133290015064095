<template>
  <div class="is-flex is-justify-content-center">
    <dashboard-chart-line
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartId="'widgetRedzone'"
    />
  </div>
</template>

<script>
import DashboardChartLine from "@/components/DashboardChartLine.vue";
import MixinDashboardWidget from "@/mixins/MixinDashboardWidget.js";

export default {
  name: "DashboardWidgetRedzonesAlerts",
  components: {
    DashboardChartLine,
  },
  props: {
    dates: {
      type: Array,
      require: true,
    },
    redzonesAlerts: {
      type: Array,
      require: true,
    },
  },
  mixins: [MixinDashboardWidget],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Redzone Alerts",
            pointBackgroundColor: "#3426D7",
            borderColor: "rgba(255, 255, 255, 0.5)",
            borderWidth: 1,
            data: [],
            tension: 0.2,
            showGrid: false,
          },
        ],
      },
    };
  },
  mounted() {
    this.setRedzoneData();
    this.setChartTitle(this.redzonesAlerts);
    this.chartOptions.plugins.title.text[1] = "Red zones";
  },
  methods: {
    setRedzoneData() {
      this.chartData.labels = this.dates
      this.chartData.datasets[0].data = this.redzonesAlerts
    },
  },
};
</script>
