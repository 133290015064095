<template>
  <div class="tabs is-centered is-boxed mt-1">
    <ul>
      <li :class="{ 'is-active': selected === '1' }" @click="updateSelected('1')">
        <a>
          <span>Camera 1</span>
        </a>
      </li>
      <li :class="{ 'is-active': selected === '2' }" @click="updateSelected('2')">
        <a>
          <span>Camera 2</span>
        </a>
      </li>
      <li :class="{ 'is-active': selected === '3' }" @click="updateSelected('3')">
        <a>
          <span>Camera 3</span>
        </a>
      </li>
      <li :class="{ 'is-active': selected === '4' }" @click="updateSelected('4')">
        <a>
          <span>All cameras</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    updateSelected(tab) {
      this.$emit('change', tab)
    }
  }
}
</script>
