<template>
  <div >
    <div class="is-flex is-justify-content-flex-end">
      <div class="dashboardbtns">
        <button class="icon btnIcon tooltip" @click="emitDownloadPDF">
          <i class="fas fa-file-pdf"></i>
          <span class="tooltiptext tinytooltip">Export to PDF</span>
        </button>
        <button
          class="icon btnIcon tooltip"
          :class="{ active: chartType == 'Bar' }"
          @click="emitSwitchChartType('Bar')"
        >
          <i class="fas fa-signal"></i>
          <span class="tooltiptext tinytooltip">View bar graph</span>
        </button>
        <button
          class="icon btnIcon tooltip"
          :class="{ active: chartType == 'Line' }"
          @click="emitSwitchChartType('Line')"
        >
          <i class="fa-solid fa-chart-line"></i>
          <span class="tooltiptext tinytooltip">View linear graph</span>
        </button>
        <button
          class="icon btnIcon tooltip"
          @click="emitSetChartDataOption"
        >
          <i class="fa-solid fa-arrows-rotate"></i>
          <span class="tooltiptext tinytooltip">Refresh</span>
        </button>
      </div>
    </div>
    <div class="tabs is-right">
      <ul>
        <li
          v-for="dateOption in dateOptions"
          :key="dateOption"
          :class="{ 'is-active': dateOption == dateOptionSelected }"
        >
          <a @click="emitSetSelectedDateOption(dateOption)">{{ dateOption }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardButtonsControls",
  props: {
    chartType: {
      type: String,
      require: true,
    },
    dateOptionSelected: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      dateOptions: ["Day", "Week", "Month", "Year"],
    }
  },
  methods: {
    emitDownloadPDF() {
      this.$emit('downloadPDF')
    },
    emitSwitchChartType(chartType) {
      this.$emit('switchChartType', chartType)
    },
    emitSetChartDataOption() {
      this.$emit('setChartDataOption', this.dateOptionSelected)
    },
    emitSetSelectedDateOption(dateOption) {
      this.$emit('setSelectedDateOption', dateOption)
    }
  }
};
</script>
