import { createStore } from "vuex";
import axios from "axios";
import widget from "./modules/widget";
import s3images from "./modules/s3Images";
import alerts from "./modules/alerts";
import beep from "../assets/sound/beep.wav";

export default createStore({
  state: {
    audio: {
      alert_sound: new Audio(beep),
      is_on: true,
      is_instantaneo: true,
      is_recorrente: true,
      has_delay: true,
    },
    filter: {
      valid: true,
      invalid: true,
      non_classified: true,
      date_start: 0,
      //date_end:new Date().getTime(),
      date_end: 2500916953418,
      camera: {},
      cameraNone: false,
      nonconformity: true,
      redzone: true,
      fall: true,
      classified: true,
      unclassified: true,
      witsml: false,
    },
    isAuthenticated: false,
    token: "",
    isLoading: false,
    camerasTotal: parseInt(process.env.VUE_APP_CAMERAS_TOTAL),
    isFromOverview: false,
    permissions: {
      restricted: false,
      unlimited: false,
    },
    localEnviroment: process.env.VUE_APP_LOCAL_ENVIRONMENT,
  },
  getters: {
    getFilter: (state) => {
      return state.filter;
    },
    GetCamerasTotal(state) {
      return state.camerasTotal;
    },
    GetIsFromOverview(state) {
      return state.isFromOverview;
    },
    GetIsAuthenticated(state) {
      return state.isAuthenticated;
    },
    GetLocalEnviroment: (state) => {
      return state.localEnviroment.toLowerCase();
    },
  },
  mutations: {
    async initializeStore(state) {
      state.audio.is_on = localStorage.getItem("audio_on");
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }
    },
    save_filter(state, filter_new) {
      state.filter.valid = filter_new.valid;
      state.filter.invalid = filter_new.invalid;
      state.filter.non_classified = filter_new.non_classified;
      state.filter.date_start = filter_new.date_start;
      state.filter.date_end = filter_new.date_end;
      state.filter.camera = filter_new.camera;
      state.filter.cameraNone = filter_new.cameraNone;
      state.filter.nonconformity = filter_new.nonconformity;
      state.filter.redzone = filter_new.redzone;
      state.filter.fall = filter_new.fall;
      state.filter.classified = filter_new.classified;
      state.filter.unclassified = filter_new.unclassified;
      state.filter.witsml = filter_new.witsml;
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.token = "";
      state.isAuthenticated = false;
    },
    setDefaultCameraFilter(state, cameras) {
      cameras.forEach((camera) => {
        state.filter.camera[camera] = true;
      });
    },
    setDefaultAlertFilter(state, statusAlerts) {
      state.filter.valid = statusAlerts.is_approved;
      state.filter.invalid = statusAlerts.is_disapproved;
      state.filter.non_classified = statusAlerts.is_validated;
    },

    setCamerasFilter(state, cameras) {
      state.filter.camera = cameras;
    },
    setIsFromOverview(state, status) {
      state.isFromOverview = status;
    },
    setPermissions(state, permissions) {
      state.permissions.restricted = permissions[0];
      state.permissions.unlimited = permissions[1];
    },
  },
  actions: {
    async getUserPermissions({ commit }) {
      try {
        const response = await axios.get("/api/v1/user-permissions");
        const { group_alert, group_camera, is_restricted, is_unlimited } =
          response.data;
        commit("setPermissions", [is_restricted, is_unlimited]);

        group_camera.forEach((group) => {
          commit("setDefaultCameraFilter", group.cameras);
        });

        group_alert.forEach((group) => {
          commit("setDefaultAlertFilter", group);
        });
      } catch (error) {}
    },
    playAudio({ state }) {
      if (state.audio.is_on) {
        if (state.audio.alert_sound.paused) {
          state.audio.alert_sound.play();
        }
      }
    },
    resetFilters({ commit, dispatch }) {
      let zero_filter = {
        valid: true,
        invalid: true,
        non_classified: true,
        date_start: 0,
        date_end: 2500916953418,
        camera: {},
        cameraNone: false,
        nonconformity: true,
        redzone: true,
        fall: true,
        classified: true,
        unclassified: true,
        witsml: false,
      };
      commit("save_filter", zero_filter);
      dispatch("setAllCamerasToFalse");
      dispatch(" getUserPermissions");
    },
    setAllCamerasToFalse({ state, commit }) {
      let cameras = {};
      for (let num_camera = 1; num_camera <= state.camerasTotal; num_camera++) {
        cameras[num_camera] = false;
      }
      commit("setCamerasFilter", cameras);
    },
    setAllCamerasToTrue({ state, commit }) {
      let cameras = {};
      for (let num_camera = 1; num_camera <= state.camerasTotal; num_camera++) {
        cameras[num_camera] = true;
      }
      commit("setCamerasFilter", cameras);
    },
    sendUserLog(context, action) {
      const data = {
        user: localStorage.getItem("harpiaUser"),
        stamp: Date.now(),
        action: action,
      };
      axios.post("api/v2/analytics/log", data);
    },
    setIsFromOverviewToTrue({ commit }) {
      commit("setIsFromOverview", true);
    },
    setIsFromOverviewToFalse({ commit }) {
      commit("setIsFromOverview", false);
    },
  },
  modules: {
    widget: widget,
    s3images: s3images,
    alerts: alerts,
  },
});
