<template>
  <div class="camera-view tooltip">
    <img
      class="camera-image is-clickable"
      :src="src"
      alt="camera"
      width="960"
      @click="openModal()"
    />
    <span class="tooltiptext">Click to view the camera in full screen</span>
  </div>
  <div v-if="isFullscreen">
    <cameras-view-image-modal
      :imageSrc="src"
      @close-camera-modal="this.closeModal()"
    />
  </div>
</template>

<script>
import CamerasViewImageModal from "@/components/CamerasViewImageModal.vue";

export default {
  components: {
    CamerasViewImageModal,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFullscreen: false,
      fullscreenSrcIndex: null,
    };
  },
  methods: {
    openModal() {
      this.isFullscreen = true;
    },
    closeModal() {
      this.isFullscreen = false;
    },
  },
};
</script>

<style scoped>
.camera-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 20px;
}

.camera-image {
  max-width: 960px;
  max-height: 100%;
  width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
</style>
