import axios from "axios";

const state = () => ({
  alerts: [],
  filters: {
    search: null,
    cameras: null,
    thumb_up: null,
    thumb_down: null,
    valid: null,
    date_added: null,
  },
});

const mutations = {
  setAlerts(state, alerts) {
    state.alerts = alerts;
  },
  updateFilters(state, filters) {
    state.filters = filters;
  },
};

const actions = {
  async fetchAlerts({ commit, state }) {
    try {
      const response = await axios.get("api/v2/alerts", {
        params: state.filters,
      });

      const alerts = response.data;

      commit("setAlerts", alerts);
    } catch (error) {
      console.error(error);
    }
  },
  updateFilters({ commit, dispatch }, filters) {
    commit("updateFilters", filters);
    dispatch("fetchAlerts");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
