<template>
  <div>
    <table
      class="mt-4 table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
    >
      <thead>
        <tr class="has-background-white-ter">
          <th>Name</th>
          <th>Status</th>
          <th>Camera</th>
          <th>People allowed</th>
          <th>Date added</th>
        </tr>
      </thead>
      <tbody v-for="redzone in redzones" :key="redzone.id">
        <tr>
          <th class="is-capitalized">{{ redzone.name }}</th>
          <th v-if="redzone.enabled">Activated</th>
          <th v-else>Disabled</th>
          <th class="is-capitalized">{{ cameras[redzone.get_camera] }}</th>
          <th class="is-capitalized">{{ redzone.limite }}</th>
          <th class="is-capitalized">
            {{ new Date(redzone.timestamp).toLocaleDateString("en-US") }}
            {{ new Date(redzone.timestamp).toLocaleTimeString("en-US") }}
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    redzones: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      cameras: {
        cam0: "Camera 1",
        cam1: "Camera 2",
        cam2: "Camera 3",
        cam3: "Camera 4",
        cam4: "Camera 5",
        cam5: "Camera 6",
        cam6: "Camera 7",
        cam7: "Camera 8",
        cam8: "Camera 9",
        cam9: "Camera 10",
      },
    };
  },
};
</script>

<style>
</style>
