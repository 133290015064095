<template>
  <div
    class="alertcard box mx-1 has-background-white-bis"
    :class="Alert.thumb_up ? 'boxUp' : Alert.thumb_down ? 'boxDown' : 'box'"
  >
    <div class="is-floating-right tooltip">
      <button
        class="button is-rounded is-small is-dark is-inverted is-focused is-danger has-background-white-bis"
        @click="generatePDF"
      >
        <span class="icon">
          <i class="fas fa-file-pdf" />
        </span>
      </button>
      <span class="tooltiptext smalltooltip">Export this alert to PDF</span>
    </div>

    <div class="is-floating-left actionbtns">
      <div class="tooltip">
        <!-- conditional comment button start -->
        <button
          class="button mx-1 is-rounded is-small is-dark is-inverted is-focused is-link has-background-white-bis"
          @click="showCommentModal = !showCommentModal"
          v-if="alertComments.length > 5"
        >
          <span class="icon">
            <i class="far fa-comment-alt fa-lg" />
          </span>
        </button>
        <button
          class="button mx-1 is-rounded is-small is-dark is-inverted is-focused is-info has-background-white-bis"
          @click="showCommentModal = !showCommentModal"
          v-else-if="alertComments.length > 0"
        >
          <span class="icon">
            <i class="far fa-comment-alt fa-lg" />
          </span>
        </button>
        <button
          class="button mx-1 is-rounded is-small is-dark is-inverted is-focused has-background-white-bis"
          @click="showCommentModal = !showCommentModal"
          v-else-if="alertComments.length == 0"
        >
          <span class="icon">
            <i class="far fa-comment-alt fa-lg" />
          </span>
        </button>

        <span class="tooltiptext smalltooltip"
          >View or add comments on this alert</span
        >
        <!-- conditional comment button end -->
      </div>
      <div class="tooltip">
        <button
          v-if="Alert.thumb_up"
          class="button mx-1 is-rounded is-small is-dark is-inverted is-focused is-success has-background-white-bis"
          @click="invertThumbUp"
        >
          <span class="icon">
            <i class="far fa-thumbs-up fa-lg" />
          </span>
        </button>

        <button
          v-else
          class="button mx-1 is-rounded is-small is-dark is-inverted is-focused has-background-white-bis"
          @click="invertThumbUp"
        >
          <span class="icon">
            <i class="far fa-thumbs-up fa-lg" />
          </span>
        </button>
        <span class="tooltiptext smalltooltip">Approve alert</span>
      </div>
      <div class="tooltip">
        <button
          v-if="Alert.thumb_down"
          class="button mx-1 is-rounded is-small is-inverted is-focused is-danger has-background-white-bis"
          @click="inverThumbDown"
        >
          <span class="icon">
            <i class="far fa-thumbs-down fa-lg" />
          </span>
        </button>
        <button
          v-else
          class="button mx-1 is-rounded is-small is-dark is-inverted is-focused has-background-white-bis"
          @click="inverThumbDown"
        >
          <span class="icon">
            <i class="far fa-thumbs-down fa-lg" />
          </span>
        </button>
        <span class="tooltiptext smalltooltip">Disapprove alert</span>
      </div>
      <div class="tooltip">
        <button
          v-if="!Alert.valid"
          class="button mx-1 is-rounded is-small is-dark is-inverted"
          @click="invertValid"
        >
          <span class="icon">
            <i class="far fa-check-square fa-lg" />
          </span>
        </button>
        <span class="tooltiptext smalltooltip">Validate alert</span>
      </div>
    </div>

    <div class="columns my-1">
      <div class="column is-4">
        <div class="has-text-underlined is-flex is-align-items-center">
          <span class="icon is-size-6">
            <i class="fa-solid fa-helmet-safety has-text-primary"></i>
          </span>
          <span
            v-if="Alert.get_category_name.toLowerCase() == 'nonconformity'"
            class="is-size-6 has-text-weight-bold has-text-primary ml-1"
            >{{ Alert.quantidade }} PPE</span
          >
          <span
            v-else
            class="is-size-6 has-text-weight-bold has-text-primary ml-1"
            >0 PPE</span
          >
        </div>

        <div class="has-text-underlined is-flex is-align-items-center">
          <span class="icon is-size-6">
            <i class="fa-solid fa-users-slash has-text-primary"></i>
          </span>
          <span
            v-if="Alert.get_category_name.toLowerCase() == 'redzone'"
            class="is-size-6 has-text-weight-bold has-text-primary ml-1"
            >{{ Alert.quantidade }} Red Zone</span
          >
          <span
            v-else
            class="is-size-6 has-text-weight-bold has-text-primary ml-1"
            >0 Red Zone</span
          >
        </div>

        <div
          v-if="showFall"
          class="has-text-underlined is-flex is-align-items-center"
        >
          <span class="icon is-size-6">
            <i class="fa-solid fa-person-falling has-text-primary"></i>
          </span>
          <span
            v-if="Alert.get_category_name.toLowerCase() == 'fall'"
            class="is-size-6 has-text-weight-bold has-text-primary ml-1"
            >{{ Alert.quantidade }} Fall</span
          >
          <span
            v-else
            class="is-size-6 has-text-weight-bold has-text-primary ml-1"
            >0 Fall</span
          >
        </div>

        <div class="has-text-underlined is-flex is-align-items-center">
          <span class="icon is-size-6">
            <i class="fa-solid fa-video has-text-primary"></i>
          </span>
          <span class="is-size-6 has-text-weight-bold has-text-primary ml-1"
            >Camera {{ Alert.camera }}</span
          >
        </div>

        <div class="has-text-grey-light is-custom-size">
          <span>ID: {{ Alert.identificador }}</span>
        </div>
      </div>

      <div class="column is-3">
        <p class="dates">
          Date: {{ new Date(Alert.timestamp).toLocaleDateString("en-US") }}
        </p>
        <p class="dates">
          Time: {{ new Date(Alert.timestamp).toLocaleTimeString("en-US") }}
        </p>
      </div>

      <div class="column is-5 is-flex is-justify-content-center">
        <div class="is-flex is-justify-content-center is-align-items-center">
          <figure class="image">
            <img
              :src="imageUrl"
              class="has-pointer-cursor"
              @click="showAlertImageModal = true"
            />
          </figure>
        </div>
      </div>

      <div>
        <modal-content
          :showModal="showAlertImageModal"
          @close-modal="showAlertImageModal = false"
        >
          <alerts-modal-images
            :alertImage="imageUrl"
            :alertImageClean="imageCleanUrl"
          />
        </modal-content>
      </div>

      <div class="modal-comments">
        <modal-card
          :showModal="showCommentModal"
          title="Information"
          @close-modal="showCommentModal = false"
        >
          <table
            :data="Alert"
            class="mb-3 table is-bordered is-striped is-hoverable is-fullwidth"
            v-if="alertComments.length"
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Hour</th>
                <th>Notes</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody v-for="comment in alertComments" :key="comment">
              <tr>
                <th>
                  {{ new Date(comment.timestamp).toLocaleDateString("en-US") }}
                </th>
                <th>
                  {{ new Date(comment.timestamp).toLocaleTimeString("en-US") }}
                </th>
                <th>{{ comment.comment }}</th>
                <th>{{ comment.user }}</th>
              </tr>
            </tbody>
          </table>
          <p v-else class="has-text-danger is-size-4">
            This alert has no comments.
          </p>
          <template #footer>
            <div class="field has-addons is-fullwidth">
              <div class="control is-expanded">
                <input
                  class="input"
                  v-model="note.comment"
                  type="text"
                  placeholder="Insert comment"
                  v-on:keyup.enter="insertNewComment()"
                />
              </div>
              <div class="control">
                <button
                  class="button is-info"
                  :disabled="note.comment == ''"
                  @click="insertNewComment()"
                >
                  Save
                </button>
              </div>
            </div>
          </template>
        </modal-card>
      </div>

      <div class="modal-confirm-thumb-up">
        <modal-card
          :showModal="showConfirmThumbUpModal"
          title="Approval Confirmed"
          @close-modal="showConfirmThumbUpModal = false"
        >
          Texto para confirmar aprovação
          <template #footer>
            <button class="button is-success" @click="invert_thumb_up">
              Approve
            </button>

            <button class="button" @click="showConfirmThumbUpModal = false">
              Cancel
            </button>
          </template>
        </modal-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AlertsModalImages from "@/components/AlertsModalImages.vue";
import ModalContent from "@/components/ModalContent.vue";
import ModalCard from "@/components/ModalCard.vue";
import { useToast } from "vue-toastification";
import notificator from "../mixins/notificator";
export default {
  props: {
    Alert: Object,
    Timer: String,
  },
  data() {
    return {
      alertstimerdisplay: "",
      modal: true,
      note: {
        comment: "",
        timestamp: "",
        user: localStorage.getItem("harpiaUser"),
      },
      local_audio_enable: true,
      fileName: "",
      valid: false,
      act: "",
      timestamp: Date.now(),
      showAlertImageModal: false,
      showCommentModal: false,
      showConfirmThumbUpModal: false,
      imageUrl: null,
      imageCleanUrl: null,
      showFall: "",
    };
  },
  components: {
    ModalContent,
    AlertsModalImages,
    ModalCard,
  },
  mixins: [notificator],
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    this.GetShowFall(); //Método temporário para esconder/mostrar alertas de queda. Idealmente, todos os alertas no array no env são ser usados condicionalmente, sem a necessidade de bools e methods separados para cada alerta, algo bem pouco eficiente.
  },
  beforeCreate() {
    if (this.$store.getters["GetLocalEnviroment"] == "cloud") {
      this.$store.dispatch("s3images/initializeS3");
    }
  },
  created() {
    this.getImages();
  },
  computed: {
    alertComments() {
      if (!this.Alert.anotacoes) {
        return "";
      }
      return this.Alert.anotacoes
        .split("/n")
        .map((comment) => JSON.parse(comment));
    },
  },
  methods: {
    GetShowFall() {
      var showFall = process.env.VUE_APP_ALERT_FALL == "true";
      this.showFall = Boolean(showFall);
    },
    alerts_timer() {
      if (!this.$store.state.permissions.restricted) {
        this.alertstimerdisplay = new Date();
        //`Current time - ${currentHour}:${currentMinute}`
        this.alertstimerdisplay = `
          ${String(this.alertstimerdisplay.getMonth() + 1).padStart(
            2,
            "0"
          )}/${String(this.alertstimerdisplay.getDate()).padStart(
          2,
          "0"
        )}/${String(this.alertstimerdisplay.getFullYear())} at
          ${String(this.alertstimerdisplay.getHours()).padStart(
            2,
            "0"
          )}:${String(this.alertstimerdisplay.getMinutes()).padStart(2, "0")}
        `;
        this.$emit("set-timer", this.alertstimerdisplay);

        let data = { time: this.alertstimerdisplay };
        axios
          .post("api/v1/last-update/", data)
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async getImages() {
      try {
        const imageInferenceNginxUrl = `${process.env.VUE_APP_NGINX_URL}/alerts_images/${this.Alert.identificador}.jpg`;
        const responseInference = await axios.get(imageInferenceNginxUrl, {
          responseType: "blob",
        });
        const urlInference = URL.createObjectURL(responseInference.data);
        this.imageUrl = urlInference;

        const imageCleanNginxUrl = `${process.env.VUE_APP_NGINX_URL}/alerts_images/${this.Alert.identificador}_clean.jpg`;
        const responseClean = await axios.get(imageCleanNginxUrl, {
          responseType: "blob",
        });
        const urlClean = URL.createObjectURL(responseClean.data);
        this.imageCleanUrl = urlClean;
      } catch (error) {
        console.log(error);
      }
    },
    handlerThumbUp() {
      this.thumb_up
        ? this.invert_thumb_up()
        : (this.showConfirmThumbUpModal = true);
    },
    getClassificationFormData(comment) {
      const formData = {
        identificador: this.Alert.identificador,
        thumb_up: this.thumb_up,
        thumb_down: this.thumb_down,
        anotacoes: comment,
        action: this.act.split(" ")[0].toLowerCase(),
      };
      return formData;
    },
    logComment(action) {
      this.note.timestamp = new Date().getTime();
      this.note.comment =
        "The user " + localStorage.getItem("harpiaUser") + " " + action;
      this.note.user = "Harpia";
      let anotacao = JSON.stringify(this.note);
      return this.Alert.anotacoes == ""
        ? JSON.stringify(this.note)
        : this.Alert.anotacoes + "/n" + anotacao;
    },
    async invertThumbUp() {
      try {
        if (this.Alert.thumb_up) {
          this.act = "LIKED";
        } else {
          this.act = "REMOVED LIKE";
        }
        let formData = {
          thumb_up: !this.Alert.thumb_up,
        };
        const response = await axios.patch(
          `api/v2/alerts/${this.Alert.identificador}`,
          formData
        );
        if (response.status === 200) {
          this.$store.dispatch("sendUserLog", this.act);
          this.act = "";
        }
      } catch (error) {
        this.notifier(error, "error");
      }
    },
    async inverThumbDown() {
      try {
        if (this.Alert.thumb_down) {
          this.act = "DISLIKED";
        } else {
          this.act = "REMOVED DISLIKE";
        }
        const formData = {
          thumb_down: !this.Alert.thumb_down,
        };
        const response = await axios.patch(
          `api/v2/alerts/${this.Alert.identificador}`,
          formData
        );
        if (response.status === 200) {
          this.$store.dispatch("sendUserLog", this.act);
          this.act = "";
        }
      } catch (error) {
        this.notifier(error, "error");
      }
    },
    async invertValid() {
      try {
        if (this.Alert.valid) {
          this.act = "VALIDATED";
        } else {
          this.act = "INVALIDATED";
        }
        const formData = {
          valid: !this.Alert.valid,
        };
        const response = await axios.patch(
          `/api/v2/alerts/${this.Alert.identificador}`,
          formData
        );
        if (response.status === 200) {
          this.$store.dispatch("sendUserLog", this.act);
        }
      } catch (error) {
        this.notifier(error, "error");
        console.log(error);
      }
    },
    getFormattedComment() {
      this.note.timestamp = new Date().getTime();
      this.note.comment = this.note.comment.replace(/\/n/g, "-n");
      this.note.comment = this.note.comment.replace(/"/g, "'");
      const _comment = JSON.stringify(this.note);
      return _comment;
    },
    async insertNewComment() {
      try {
        this.act = "COMMENTED";

        const comment = this.getFormattedComment();

        let formData = {
          anotacoes: comment,
        };

        await axios.patch(
          `/api/v2/alerts/${this.Alert.identificador}`,
          formData
        );
        this.notifier("Inserted annotations.", "success");
        this.commentModal = false;
        this.note.comment = "";
        this.$store.dispatch("sendUserLog", this.act);
      } catch (error) {
        this.notifier("Failed to save notes.", "error");
        console.log(error);
      }
    },

    reading_notes() {
      this.comments = [];
      if (this.Alert.anotacoes !== "") {
        this.Alert.anotacoes.split("/n").forEach((note) => {
          this.comments.push(JSON.parse(note));
        });
      }
    },
    generatePDF() {
      // json com os dados do alerta e quem está logado. Enviado para a api de criar pdf
      let json = {
        alert: this.Alert,
        user: localStorage.getItem("harpiaUser"),
      };
      axios
        .post("api/v2/reports/alert-pdf", json)
        .then((response) => {
          this.fileName = response.data.name;
          // parte que realiza o download, igual ao csv
          const url = `${process.env.VUE_APP_NGINX_URL}/pdf/${this.fileName}`;
          axios
            .get(url, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], { type: "file/pdf" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = this.fileName;
              link.click();
              URL.revokeObjectURL(link.href);
              this.notifier("PDF file downloading", "info");
              // log no comment
              this.note.comment = `The user ${localStorage.getItem(
                "harpiaUser"
              )} downloaded the pdf.`;
              const comment = this.getFormattedComment();
              const formData = {
                anotacoes: comment,
              };
              axios
                .patch(`/api/v2/alerts/${this.Alert.identificador}`, formData)
                .then((response) => {})
                .catch((error) => {
                  console.log(error);
                });
              this.note.comment = "";
              this.note.user = localStorage.getItem("harpiaUser");
            })
            .catch((error) => {
              console.log(error);
              this.notifier("Something went wrong", "error");
            });
        })
        .catch((error) => {
          console.log(error);
          this.notifier("Something went wrong", "error");
        });

      this.act = "DOWNLOADED PDF";
      const data = {
        user: localStorage.getItem("harpiaUser"),
        stamp: Date.now(),
        action: this.act,
      };
      axios.post("api/v2/analytics/log", data).catch((error) => {
        console.log(error);
        if (error.response) {
          for (const property in error.response.data) {
            // this.errors.push(`${property}: ${error.response.data[property]}`)
            this.notifier(
              `${property}: ${error.response.data[property]}`,
              "error"
            );
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.boxDown {
  border-color: red;
  border-style: solid;
  border-width: 0.05vh;
}
.boxUp {
  border-color: green;
  border-style: solid;
  border-width: 0.05vh;
}
.table {
  overflow: auto;
}
.fullImage {
  display: block;
  min-width: 160vh;
  max-width: 160vh;
  height: auto;
}
.has-text-underlined {
  border-bottom: 2px solid rgba(42, 157, 143, 0.3);
}

.is-custom-size {
  font-size: 1.1vh;
}

.has-pointer-cursor {
  cursor: pointer;
}
.is-floating-left {
  float: right;
  margin-top: -35px;
  margin-right: -30px;
}

.is-floating-right {
  float: left;
  margin-top: -35px;
  margin-left: -15px;
}

.dates {
  padding: 0.01vh 0.1vw 0.01vh;
  border: 0.5px solid rgb(82, 82, 82);
  border-radius: 8px;
  margin-top: 1vh;
  font-size: 1.5vh;
}
.is-fullwidth {
  width: 100%;
}
</style>
