<template>
  <div ref="redzones">
    <div class="is-flex is-flex-direction-row is-align-items-flex-start mb-2">
      <div class="is-inline-block is-clickable" @click="toggleHidden()">
        <button class="arrow mr-2" :class="{ 'arrow-hidden': isHidden }">
          <i class="fas fa-chevron-down"></i>
        </button>
        <span class="mt-1 is-size-4 is-size-6-touch">Redzones</span>
      </div>
      <div class="ml-auto" :class="{ 'btn-hidden': isHidden }">
        <button class="icon btnIcon tooltip" @click="downloadPDF">
          <i class="fas fa-file-pdf"></i>
          <span class="tooltiptext">Export to PDF</span>
        </button>
        <button class="icon btnIcon ml-auto tooltip" @click="getRedzone()">
          <i class="fa-solid fa-arrows-rotate"></i>
          <span class="tooltiptext">Refresh</span>
        </button>
      </div>
    </div>
    <div :class="{ hidden: isHidden }" class="data">
      <div
        class="is-loading-bar has-text-centered"
        :class="{ 'is-loading': isRedzonesLoading }"
      >
        <div class="lds-dual-ring"></div>
      </div>
      <!-- level  -->
      <dashboard-red-zones-level :total="total" />

      <hr />
      <!-- cards -->
      <div class="p-5 redzones-cards">
        <dashboard-red-zones-card
          v-for="redzone in redzones"
          :key="redzone.id"
          :redzone="redzone"
        />
      </div>
      <hr />
      <!-- table -->
      <div v-if="total.redzones > 0" class="table-container">
        <div>
          <dashboard-red-zones-table :redzones="redzones" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MixinDashboard from "@/mixins/MixinDashboard.js";
import DashboardRedZonesCard from "@/components/DashboardRedZonesCard.vue";
import DashboardRedZonesLevel from "@/components/DashboardRedZonesLevel.vue";
import DashboardRedZonesTable from "@/components//DashboardRedZonesTable.vue";

export default {
  name: "DashboardRedZones",
  components: {
    DashboardRedZonesCard,
    DashboardRedZonesLevel,
    DashboardRedZonesTable,
  },
  mixins: [MixinDashboard],
  data() {
    return {
      isHidden: false,
      isRedzonesLoading: false,
      redzones: [],
      total: { redzones: 0, activatedRedzones: 0, disabledRedzones: 0 },
    };
  },
  methods: {
    async getRedzone() {
      this.resetTotal();
      let redzonesFetcheds = [];
      for (let cam = 0; cam < 10; cam++) {
        await axios
          .get(`/api/v2/redzones/load_rz/${cam}`)
          .then((response) => {
            if (response.data.length) {
              let respRedzones = response.data;
              respRedzones.forEach((redzone) => {
                redzonesFetcheds.push(redzone);
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.isRedzonesLoading = false;
          });
      }
      this.redzones = redzonesFetcheds;
      this.setTotal();
    },
    setTotal() {
      this.total.redzones = this.redzones.length;
      this.total.activatedRedzones = this.redzones.filter(
        (redzone) => redzone.enabled
      ).length;
      this.total.disabledRedzones = this.redzones.filter(
        (redzone) => !redzone.enabled
      ).length;
      this.isRedzonesLoading = false;
    },
    resetTotal() {
      this.redzones = [];
      this.isRedzonesLoading = true;
      this.total.redzones = 0;
      this.total.activatedRedzones = 0;
      this.total.disabledRedzones = 0;
    },
    downloadPDF() {
      this.isRedzonesLoading = true;
      this.notifier("Generating PDF file...", "info");
      this.generatePDF("redzones").then(() => {
        this.notifier("PDF is ready", "success");
        this.isRedzonesLoading = false;
      });
    },
  },
  created() {
    this.getRedzone();
  },
};
</script>

<style lang="scss" scoped>
.redzones-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, max(100px));
  gap: 4%;
  max-height: 200px;
  overflow: auto;
}
.btnIcon {
  justify-self: flex-end;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.btnIcon:hover {
  color: gray;
}
.table-container {
  max-height: 200px;
  overflow-y: auto;
}
</style>
