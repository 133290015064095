const MixinDashboardDates = {
  data() {
    return {
      monthsNames: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
      _mixinDatesWeekDaysNames: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      hours: [],
    };
  },
  methods: {
    setFirstLoadWeek() {
      const today = new Date();
      const goBack = (today.getDay() + 6) % 7;

      const lastMonday = new Date(today.getTime());
      lastMonday.setUTCDate(today.getUTCDate() - goBack);

      this.week[0] = new Date(lastMonday);

      const lastSunday = new Date(this.week[0].getTime());
      lastSunday.setUTCDate(this.week[0].getUTCDate() + 6);

      this.week[1] = new Date(lastSunday);
    },
    getDatesInRange(startDate, endDate) {
      const date = new Date(startDate.getTime());

      const dates = [];

      while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    setSelectedDateOption(value) {
      this.dateOptionSelected = value;
    },
    setHoursArray() {
      for (var i = 0; i < 24; i++) {
        let hour = i;
        let suffix = "AM";
        if (i >= 12) {
          suffix = "PM";
          if (i > 12) {
            hour -= 12;
          }
        }

        hour = hour < 10 ? "0" + hour : hour;
        this.hours.push((hour == "00" ? "12" : hour) + ":00 " + suffix);
      }
    },
    _mixinDatesGetFormattedDatesPerMonth(datesArr) {
      return datesArr
        .map((data) => {
          const parts = data.date.split("-");
          return `${parts[1]}/${parts[2]}/${parts[0]}`;
        })
        .reverse();
    },
    _mixinDatesGetMonthsNamesFromData(data) {
      let months = [];
      Object.keys(data).forEach((key) => {
        months.push(this.monthsNames[key]);
      });
      return months;
    },
  },
  created() {
    this.setFirstLoadWeek();
    this.setHoursArray();
  },
};
export default MixinDashboardDates;
