import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import Konva from "konva";
import VueKonva from "vue3-konva";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "./assets/fonts/FontAwesome6/css/all.css";
import "./assets/fonts/FontAwesome6/js/all.js";

require("./styles/harpia.scss")
require("./styles/extrastyles.scss")
require("./styles/fontstyles.css")


// magia pra nao precisar chamar a api pelo endereço completo
axios.defaults.baseURL = process.env.VUE_APP_API_URL



//monta esse App no public-->index.html
//createApp(App).use(store).use(router).mount('#app')
const app = createApp(App);
app.use(store);
app.use(router, axios);
app.use(VueKonva); // app para desenhar na tela
app.use(Konva);
app.use(Toast, {
    transition: "Vue-Toastification__slideBlurred",
    maxToasts: 5,
    newestOnTop: true,
    filterBeforeCreate: (toast, toasts) => {
      if (toasts.filter(
        t => t.type === toast.type
      ).length !== 0) {
        // Returning false discards the toast
        return false;
      }
      // You can modify the toast if you want
      return toast;
    },
    mounted() {
        this.$router.replace("/")
    }
  });
app.mount('#app');
