<template>
  <div class="tooltip">
    <button
      class="button ml-2 has-text-light is-responsive is-primary"
      :disabled="disableBtn"
      @click="doAction()"
    >
      <span class="icon">
        <i :class="btnIcon"></i>
      </span>
      <span class="is-family-sans-serif"><slot></slot></span>
      <span class="tooltiptext smalltooltip"
      v-if="this.action == 'refreshPage'"
      >
        Reset all filters to defaults
      </span>
      <span class="tooltiptext smalltooltip"
      v-else
      >
        Confirm and search
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      required: true,
    },
    action: {
      type: String,
    },
    btnIcon: {
      type: String,
    },
  },
  computed: {
    disableBtn() {
      if (this.action == "refreshPage") return false;
      if (this.date == "Filter by date") return true;
      return false;
    },
  },
  methods: {
    doAction() {
      console.log(this.action);
      this.$emit(this.action);
    },
  },
};
</script>

<style></style>
