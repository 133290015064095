<template>
  <div class="columns is-multiline is-centered m-1">
    <div v-for="(src, index) in srcs" :key="index" class="column is-one-third">
      <div class="card tooltip">
        <div class="card-image">
          <figure class="image is-clickable" @click="openModal(index)">
            <img :src="src" :alt="`camera ${index}`" />
          </figure>
          <span class="tooltiptext">Click to view this camera in full screen</span>
        </div>
      </div>
      <div v-if="isFullscreen">
        <cameras-view-image-modal
          :imageSrc="srcs[fullscreenSrcIndex]"
          @close-camera-modal="this.closeModal()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CamerasViewImageModal from "@/components/CamerasViewImageModal.vue";
export default {
  components: {
    CamerasViewImageModal,
  },
  props: {
    srcs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isFullscreen: false,
      fullscreenSrcIndex: null,
    };
  },
  methods: {
    openModal(index) {
      this.fullscreenSrcIndex = index;
      this.isFullscreen = true;
    },
    closeModal() {
      this.fullscreenSrcIndex = null;
      this.isFullscreen = false;
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
</style>
