<template>
  <div class="is-flex is-justify-content-center">
    <dashboard-chart-line
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartId="'WidgetThisMonth'"
    />
  </div>
</template>

<script>
import axios from "axios";
import DashboardChartLine from "@/components/DashboardChartLine.vue";
import MixinDashboardWidget from "@/mixins/MixinDashboardWidget.js";

export default {
  name: "DashboardWidgetThisMonth",
  components: {
    DashboardChartLine,
  },
  mixins: [MixinDashboardWidget],
  data() {
    return {
      month: {
        month: new Date().getMonth() - 3,
        year: new Date().getFullYear(),
      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Total alerts",
            pointBackgroundColor: "#3426D7",
            borderColor: "rgba(255, 255, 255, 0.5)",
            borderWidth: 1,
            data: [],
            tension: 0.2,
            showGrid: false,
          },
        ],
      },
      totalAlertsNow: 0,
      totalAlertspast: 0,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  created() {
    this.getMonthData();
    this.chartOptions.plugins.title.text[1] = "Total alerts this month";
  },
  methods: {
    async getMonthData() {
      let dates = [];
      let totalAlerts = [];
      for (let i = 1; i <= 4; i++) {
        await axios
          .get(
            `/api/v2/reports/month/${this.month.month + i}-${this.month.year}`
          )
          .then((response) => {
            dates.push(this.monthNames[this.month.month + i - 1]);
            totalAlerts.push(response.data.total);
            this.chartData.labels = dates.slice(-4);
            this.chartData.datasets[0].data = totalAlerts.slice(-4);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.setChartTitle(totalAlerts);
    },
  },
};
</script>
