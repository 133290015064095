<template>
  <div ref="class">
    <div class="is-flex is-align-items-flex-start mb-2">
      <div class="is-inline-block is-clickable" @click="toggleHidden()">
        <button class="arrow mr-2" :class="{ 'arrow-hidden': isHidden }">
          <i class="fas fa-chevron-down"></i>
        </button>
        <span class="mt-1 is-size-4 is-size-6-touch">Per scenario</span>
      </div>
    </div>
    <div :class="{ hidden: isHidden }" class="data">
      <div class="is-flex is-justify-content-space-between">
        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
          <!-- start datapciker -->
          <div class="is-flex is-flex-direction-column tooltip">
            <Datepicker
              locale="en-US"
              v-if="dateOptionSelected == 'Year'"
              v-model="year"
              yearPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Month'"
              v-model="month"
              monthPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Week'"
              v-model="week"
              weekPicker
              :clearable="false"
            />
            <Datepicker
              locale="en-US"
              v-else-if="dateOptionSelected == 'Day'"
              v-model="day"
              dayPicker
              :clearable="false"
              :enableTimePicker="false"
            />
            <span class="tooltiptext">Select a specific date</span>
          </div>
          <!-- end datapciker -->
        </div>

        <!-- start chart type select and date option select -->
        <dashboard-buttons-controls
          :chartType="chartType"
          :dateOptionSelected="dateOptionSelected"
          @download-PDF="downloadPDF"
          @switch-chart-type="switchChartType"
          @set-chart-data-option="setChartDataOption"
          @set-selected-date-option="setSelectedDateOption"
        />
        <!-- end chart type select and date option select -->
      </div>

      <div>
        <!-- start charts components -->
        <div
          class="is-loading-bar has-text-centered"
          v-bind:class="{ 'is-loading': this.isClassLoading }"
        >
          <div class="lds-dual-ring"></div>
        </div>
        <dashboard-chart-bar
          id="per-class"
          v-if="chartType == 'Bar'"
          :chart-data="chartData"
          :chartOptions="chartOptions"
        />
        <dashboard-chart-line
          id="per-class"
          v-if="chartType == 'Line'"
          :chart-data="chartData"
          :chartOptions="chartOptions"
          :height="300"
        />
        <!-- end charts components -->
      </div>
      <hr />

      <!-- start total alerts end percent -->
      <div class="mb-5 has-text-centered">
        <span class="is-size-4">Total {{ totalAlerts }}</span>
      </div>
      <div class="columns">
        <div
          v-for="(alertType, index) in alertTypes"
          :key="index"
          class="column has-text-centered"
        >
          <div class="has-text-grey-darker is-capitalized">{{ alertType }}</div>
          <div class="has-text-weight-bold">
            {{ totalClasses[index] }} ({{
              percentClasses[index] !== undefined
                ? percentClasses[index].toFixed(2)
                : "0"
            }}%)
          </div>
          <div class="progress-container">
            <div
              class="progress-bar"
              :class="`is-type${index + 1}`"
              :style="{ width: percentClasses[index] + '%' }"
            ></div>
          </div>
        </div>
      </div>
      <!-- end total alerts end percent -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DashboardChartBar from "@/components/DashboardChartBar.vue";
import DashboardChartLine from "@/components/DashboardChartLine.vue";
import DashboardButtonsControls from "@/components/DashboardButtonsControls.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MixinDashboardTotalAlerts from "@/mixins/MixinDashboardTotalAlerts.js";
import MixinDashboard from "@/mixins/MixinDashboard.js";
import MixinDashboardDates from "@/mixins/MixinDashboardDates.js";

export default {
  name: "TotalAlertsPerClass",
  components: {
    DashboardChartBar,
    DashboardChartLine,
    DashboardButtonsControls,
    Datepicker,
  },
  mixins: [MixinDashboardTotalAlerts, MixinDashboard, MixinDashboardDates],
  data() {
    return {
      chartType: "Bar",
      year: new Date().getFullYear(),
      month: { month: new Date().getMonth(), year: new Date().getFullYear() },
      week: [],
      day: new Date().toLocaleDateString("en-US"),
      dateOptionSelected: "Month",
      chartData: { labels: [], datasets: [] },
      chartOptions: {},
      isClassLoading: false,
      isHidden: false,
      totalAlerts: 0,
      dashboardTooltipType: "sum",
      alertTypes: process.env.VUE_APP_ALERT_TYPES.split(","),
      percentClasses: [],
      totalClasses: [],
    };
  },
  watch: {
    dateOptionSelected: {
      handler() {
        this.setChartDataOption();
      },
    },
    year: {
      handler() {
        this.getChartDataYear();
      },
    },
    month: {
      handler() {
        this.getChartDataMonth();
      },
    },
    week: {
      handler() {
        this.getChartDataOptionWeek();
      },
    },
    day: {
      handler() {
        this.isClassLoading = true;
        this.getChartDataOptionDay();
      },
    },
  },
  methods: {
    getAlertTypesObj() {
      let _alertTypesObj = {};

      for (var i = 0; i < this.alertTypes.length; i++) {
        _alertTypesObj[this.alertTypes[i]] = [];
      }
      return _alertTypesObj;
    },
    extractAlertTypesObjFromDailyData(dailyData) {
      const _alertTypesObj = this.getAlertTypesObj();
      dailyData.forEach((data) => {
        let nonconformity_type = this.sumHours(data.hour);
        this.totalAlerts += nonconformity_type.reduce(this.sum, 0);

        for (let i = 0; i < this.alertTypes.length; i++) {
          if (i < nonconformity_type.length) {
            _alertTypesObj[this.alertTypes[i]].unshift(nonconformity_type[i]);
          } else {
            _alertTypesObj[this.alertTypes[i]].unshift(0);
          }
        }
      });
      return _alertTypesObj;
    },
    extractDataFromHours(hours) {
      const _alertTypesObj = this.getAlertTypesObj();
      for (let hour of hours) {
        for (let i = 0; i < this.alertTypes.length; i++) {
          _alertTypesObj[this.alertTypes[i]].push(hour[i]);
        }
      }
      return _alertTypesObj;
    },
    async getChartDataYear() {
      try {
        this.isClassLoading = true;

        const response = await axios.get(`/api/v2/reports/year/${this.year}`);
        this.totalAlerts = response.data.total;
        const nonconformity_type = response.data.nonconformity;
        let months = this._mixinDatesGetMonthsNamesFromData(nonconformity_type);
        const alertTypesObj = this.getAlertTypesObj();

        Object.values(nonconformity_type).forEach((value) => {
          for (let i = 0; i < this.alertTypes.length; i++) {
            if (i < value.length) {
              alertTypesObj[this.alertTypes[i]].push(value[i]);
            } else {
              alertTypesObj[this.alertTypes[i]].push(0);
            }
          }
        });
        this.setChartData(months, alertTypesObj);
        this.setPercentTotalPerClass(alertTypesObj);
      } catch (error) {
        console.log(error);
      } finally {
        this.isClassLoading = false;
      }
      let hardHat_arr = [];
      let uniform_arr = [];
      let gloves_arr = [];
      let boots_arr = [];
      let lifejacket_arr = [];
      let redzone_arr = [];
      let fall_arr = [];
      this.totalAlerts = 0;

      await axios.get(`/api/v2/reports/year/${this.year}`).then((response) => {
        this.totalAlerts = response.data.total;
        const nonconformity_type = response.data.nonconformity;

        Object.values(nonconformity_type).forEach((value) => {
          hardHat_arr.push(value[0]);
          uniform_arr.push(value[1]);
          gloves_arr.push(value[2]);
          boots_arr.push(value[3]);
          lifejacket_arr.push(value[4]);
          redzone_arr.push(value[5]);
          fall_arr.push(value[6]);
        });

        const yearData = {
          hardHat: hardHat_arr,
          uniform: uniform_arr,
          gloves: gloves_arr,
          boots: boots_arr,
          lifejacket: lifejacket_arr,
          redzone: redzone_arr,
          fall: fall_arr,
        };

        // this.setChartData(months, yearData);
        // this.setPercentTotalPerClass(yearData);
      });
    },
    async getChartDataMonth() {
      try {
        this.isClassLoading = true;

        const response = await axios.get(
          `/api/v2/reports/month-day/${this.month.month + 1}-${this.month.year}`
        );
        this.totalAlerts = 0;
        const dailyData = response.data.daily;
        const days = this._mixinDatesGetFormattedDatesPerMonth(dailyData);
        const alertTypesObj = this.extractAlertTypesObjFromDailyData(dailyData);
        this.setChartData(days, alertTypesObj);
        this.setPercentTotalPerClass(alertTypesObj);
      } catch (error) {
        console.log(error);
      } finally {
        this.isClassLoading = false;
      }
    },
    async getChartDataOptionWeek() {
      try {
        this.isClassLoading = true;

        let alertTypesObj = this.getAlertTypesObj();
        let dates_arr = this.getDatesInRange(this.week[0], this.week[1]);
        let total = 0;

        for (let date of dates_arr) {
          let day = new Date(date)
            .toLocaleDateString("pt-br")
            .replaceAll("/", "-");

          const response = await axios.get(`/api/v2/reports/day/${day}`);
          let nonconformity_type = this.sumHours(response.data.hour);
          total += nonconformity_type.reduce(this.sum, 0);

          for (let i = 0; i < this.alertTypes.length; i++) {
            if (i < nonconformity_type.length) {
              alertTypesObj[this.alertTypes[i]].push(nonconformity_type[i]);
            } else {
              alertTypesObj[this.alertTypes[i]].push(0);
            }
          }
          this.totalAlerts = total;
        }
        this.setChartData(this._mixinDatesWeekDaysNames, alertTypesObj);
        this.setPercentTotalPerClass(alertTypesObj);
      } catch (error) {
        console.log(error);
      } finally {
        this.isClassLoading = false;
      }
    },
    async getChartDataOptionDay() {
      try {
        this.isClassLoading = true;
        let day = new Date(this.day)
          .toLocaleDateString("pt-br")
          .replaceAll("/", "-");
        const response = await axios.get(`/api/v2/reports/day/${day}`);
        let nonconformity_type_total = this.sumHours(response.data.hour);
        const alertTypesObj = this.extractDataFromHours(response.data.hour);
        this.totalAlerts = nonconformity_type_total.reduce(this.sum, 0);
        this.setChartData(this.hours, alertTypesObj);
        this.setPercentTotalPerClass(alertTypesObj);
      } catch (error) {
        console.log(error);
      } finally {
        this.isClassLoading = false;
      }
    },
    configureChartDatasets() {
      this.chartData.datasets = [];
      for (let i = 0; i < this.alertTypes.length; i++) {
        const color = getComputedStyle(
          document.documentElement
        ).getPropertyValue(`--type${i + 1}`);
        this.chartData.datasets.push({
          label: this.alertTypes[i],
          backgroundColor: color,
          pointBackgroundColor: color,
          borderColor: color,
          borderWidth: 2,
          data: [],
          tension: 0.3,
        });
      }
    },
    setChartData(dates, data) {
      for (let i = 0; i < this.alertTypes.length; i++) {
        this.chartData.datasets[i].data = data[this.alertTypes[i]];
      }
      this.chartData.labels = dates;
    },
    setPercentTotalPerClass(data) {
      this.percentClasses = [];
      this.totalClasses = [];
      for (let i = 0; i < this.alertTypes.length; i++) {
        let key = this.alertTypes[i];
        let _totalClass = data[key].reduce(this.sum, 0);
        this.totalClasses.push(isNaN(_totalClass) ? 0 : _totalClass);

        let _percentClass = (_totalClass * 100) / this.totalAlerts;
        this.percentClasses.push(isNaN(_percentClass) ? 0 : _percentClass);
      }
    },
    sumHours(arrays) {
      return arrays.reduce(
        (acc, array) => acc.map((sum, i) => sum + array[i]),
        new Array(arrays[0].length).fill(0)
      );
    },
    downloadPDF() {
      this.isClassLoading = true;
      this.notifier("Generating PDF file...", "info");
      this.generatePDF("class").then(() => {
        this.notifier("PDF is ready", "success");
        this.isClassLoading = false;
      });
    },
  },
  created() {
    this.setChartDataOption();
    this.configureChartDatasets();
  },
};
</script>
