<template>
  <div>
    <div
      class="dropdown my-1"
      :class="{ 'is-hoverable': redzones.length >= 1 }"
    >
      <div class="dropdown-trigger">
        <button
          class="button is-dark is-outlined fullWidth is-small tooltip redzonebtn"
          aria-haspopup="true"
          aria-controls="dropdown-menu3"
          :disabled="redzones.length < 1"
        >
          <span class="icon is-small">
            <i class="fas fa-upload" aria-hidden="true"></i>
          </span>
          <span>LOad</span>
          <span class="tooltiptext redzonetooltip">Load an existing Red Zone configuration</span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content p-1">
          <div
            v-for="redzone in redzones"
            :key="redzone.name"
            :value="redzone"
            aria-role="listitem"
          >
            <div class="dropdown-item">
              <div class="">
                <div
                  class="box is-flex has-background-success-light is-clickable is-justify-content-space-between p-1"
                  :class="{ 'red-zone-selected': redzone.name == rzSelected }"
                  @click="selectRedZone(redzone)"
                >
                  <span
                    class="is-size-7 has-text-success-dark has-text-weight-medium ml-2"
                  >
                    {{ redzone.name.toUpperCase() }}
                  </span>
                  <button
                    class="delete is-small"
                    @click.stop="deleteRedZone(redzone)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadRedZonesDropdown",
  props: {
    redzones: {
      type: Array,
    },
    rzSelected: {
      type: String,
    },
  },
  methods: {
    selectRedZone(redzone) {
      this.$emit("selectRedZone", redzone);
    },
    deleteRedZone(redzone) {
      this.$emit("deleteRedZone", redzone);
    },
  },
};
</script>

<style scoped lang="scss">
.fullWidth {
  width: 15vw;
}
.red-zone-selected {
  border: 1px solid #23d160;
}
</style>
