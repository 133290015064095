import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import notificator from "./notificator";

const MixinDashboard = {
  mixins: [notificator],
  methods: {
    toggleHidden() {
      this.isHidden = !this.isHidden;
    },
    sum(accumulator, a) {
      return accumulator + a;
    },
    setChartDataForCamerasAndSystemAvailability(dates, data) {
      for (let i = 0; i < this.camerasTotal; i++) {
        const key = `cam${i + 1}`;
        this.chartData.datasets[i].data = data[key];
      }

      this.chartData.labels = dates;
    },
    createDataObject(camArr) {
      const data = {};
      for (let i = 0; i < this.camerasTotal; i++) {
        data[`cam${i + 1}`] = camArr[i];
      }
      return data;
    },
    async generatePDF(chart) {
      const htmlContent = this.$refs[chart];

      const canvas = await html2canvas(htmlContent, { scale: 2 });
      const imageData = canvas.toDataURL("image/png");

      const altaveLogo = new Image();
      altaveLogo.src = require("@/assets/media/images/harpia-color full-iconAsset 11.png");

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
      });

      const imgProps = pdf.getImageProperties(imageData);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20; // subtrai 20 mm das margens laterais
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const date = new Date(Date.now()).toLocaleDateString("en");
      const timestamp = date.toString();

      pdf.addImage(imageData, "PNG", 10, 30, pdfWidth, pdfHeight);
      pdf.addImage(altaveLogo, "PNG", pdfWidth - 20, 10, 30, 10);
      pdf.text(date, 10, 15);
      pdf.save(`Dashboard_${chart}_${timestamp}.pdf`);
    },
  },
};
export default MixinDashboard;
