<template>
  <div>
    <div class="card mt-5">
      <div>
        <p class="title is-size-7">Active Red Zones:</p>
      </div>
      <div v-if="activeRedzones.length">
        <ul class="m-1" v-for="rz in activeRedzones" :key="rz.name">
          <button
            class="button is-outlined is-danger is-fullwidth is-small"
            outlined
            rounded
            @click="disabledRedZone(rz)"
          >
            <span class="icon">
              <i class="fas fa-trash" />
            </span>
            <span>{{ rz.name }} ({{ rz.limite }})</span>
          </button>
        </ul>
      </div>
      <div v-else class="is-size-6 card">
        <p><i>No Red Zone active</i></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeRedzones: {
      type: Array,
    },
  },
  methods: {
    disabledRedZone(rz) {
      this.$emit("disabledRedZone", rz);
    },
  },
};
</script>

<style></style>
