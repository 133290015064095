const MixinDashboardWidget={
    methods: {
        setChartTitle(alerts) {
            this.totalAlertsNow = alerts[alerts.length - 1] || 0
            this.totalAlertsPast = alerts[alerts.length - 2] || 0
            let percentText = ''
            let percent = 0
            percent = (this.totalAlertsNow - this.totalAlertsPast) / this.totalAlertsPast * 100
            percent === Infinity || !percent ? percent = 0 : percent
            percentText = `${this.totalAlertsNow} (${percent.toFixed(2)}%)`
            this.chartOptions.plugins.title.text[0] = percentText
        },
        configureChartOptions() {
            this.chartOptions = {
                responsive: false,
                maintainAspectRatio: true,
                plugins: {
                    legend: false,
                    title: {
                        display: true,
                        text: ['', ''],
                        color: 'white'
                    },
                },
                scales: {
                    x: {
                        grid: {         
                        display: false,
                        },
                        ticks: {
                        display: false               
                        }
                    },
                    y: {
                        grid: {       
                        display: false  
                        },
                        ticks: { 
                        display: false,
                        }
                    }
                }
            }
        }
    },
    created() {
        this.configureChartOptions()
    }
}
export default MixinDashboardWidget