<template>
  <!-- This template represents a dashboard view that displays alert data. It has a
  navbar component, a widget component that shows a quick view of the latest
  alerts generated on the day and month, three chart components that show the
  total alerts by class, by camera, and by zones, and a component that displays
  cards and a table with active redzones on the camera. The view is divided into
  several separate components to make it easier to maintain and reuse code. Each
  component is responsible for displaying a specific set of data and
  functionality. When the component is rendered, the child components are
  included in the final rendering of the dashboard. -->
  <div>
    <harpia-bar />

    <div class="dashboard pagebody">
      <!-- Navbar component -->
      <div class="testingwatermark" v-if="TestMode">
        <h1>
          <i class="fa-solid fa-triangle-exclamation"></i>
          <br />
          System in Testing
        </h1>
      </div>
      <!-- Widget component for displaying quick view of latest alerts -->
      <button
        class="button is-primary mt-2 tooltip"
        :class="{ 'is-loading': this.isDownloadLoading }"
        @click="downloadPDF"
      >
        Download dashboard PDF
        <span class="tooltiptext smalltooltip"
          >Download this dashboard as PDF
        </span>
      </button>

      <div ref="full">
        <div class="pdf-section">
          <dashboard-widget />

          <div class="box dashboardbox has-background-white-bis">
            <dashboard-alerts-per-class />
          </div>
        </div>
        <div class="pdf-section">
          <div class="box dashboardbox has-background-white-bis">
            <dashboard-alerts-per-camera />
          </div>
          <!-- Component for displaying total alerts per zone -->
          <div v-if="false" class="box dashboardbox has-background-white-bis">
            <dashboard-alerts-per-zone />
          </div>
        </div>
        <div class="pdf-section">
          <div
            v-if="SysAvailable"
            class="box dashboardbox has-background-white-bis"
          >
            <dashboard-system-availability />
          </div>
          <!-- Component for displaying user data -->
          <div class="box dashboardbox has-background-white-bis">
            <dashboard-users />
          </div>
        </div>
        <div class="box pdf-section dashboardbox has-background-white-bis">
          <dashboard-red-zones />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../styles/extrastyles_dashboard.scss";
</style>

<script>
import HarpiaBar from "@/components/HarpiaBar.vue";
import DashboardRedZones from "@/components/DashboardRedZones.vue";
import DashboardAlertsPerCamera from "@/components/DashboardAlertsPerCamera.vue";
import DashboardAlertsPerClass from "@/components/DashboardAlertsPerClass.vue";
import DashboardAlertsPerZone from "@/components/DashboardAlertsPerZone.vue";
import DashboardSystemAvailability from "@/components/DashboardSystemAvailability.vue";
import DashboardUsers from "@/components/DashboardUsers.vue";
import DashboardWidget from "@/components/DashboardWidget.vue";
import MixinDashboard from "@/mixins/MixinDashboard.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// Mapping of component name to component file path
const components = {
  HarpiaBar, // Navbar component
  DashboardRedZones, // Component for displaying active redzones on camera
  DashboardAlertsPerCamera, // Component for displaying total alerts per camera
  DashboardAlertsPerClass, // Component for displaying total alerts per class
  DashboardAlertsPerZone, // Component for displaying total alerts per zone
  DashboardSystemAvailability,
  DashboardUsers, // Component for displaying user data
  DashboardWidget, // Widget component for displaying quick view of latest alerts
};

export default {
  name: "Dashboard",
  components,
  mixins: [MixinDashboard],
  // Hook for updating the page title when the component is mounted
  mounted() {
    document.title = "Dashboard | Harpia";
    this.TestModeSet();
    this.GetSysAvailable();
  },
  data() {
    return {
      isDownloadLoading: false,
      TestMode: "",
      SysAvailable: "",
    };
  },
  methods: {
    GetSysAvailable() {
      var SysBool = process.env.VUE_APP_SYS_AVAILABLE == "true";
      this.SysAvailable = Boolean(SysBool);
    },

    TestModeSet() {
      var TestBool = process.env.VUE_APP_TestMode == "true";
      this.TestMode = Boolean(TestBool);
    },

    async downloadPDF() {
      this.isDownloadLoading = true;
      this.notifier("Generating PDF file...", "info");
      const htmlContent = this.$refs.full;

      const pdfSections = htmlContent.querySelectorAll(".pdf-section");

      const altaveLogo = new Image();
      altaveLogo.src = require("@/assets/media/images/harpia-color full-iconAsset 11.png");
      const date = new Date(Date.now()).toLocaleDateString("en");
      const timestamp = date.toString();

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
      });
      const pageHeight = pdf.internal.pageSize.getHeight();

      for (let i = 0; i < pdfSections.length; i++) {
        const section = pdfSections[i];

        const canvas = await html2canvas(section, { scale: 1.5 });
        const imageData = canvas.toDataURL("image/png");

        const imgProps = pdf.getImageProperties(imageData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        if (i > 0) {
          pdf.addPage();
        }
        pdf.addImage(altaveLogo, "PNG", pdfWidth - 20, 10, 30, 10);
        pdf.addImage(imageData, "PNG", 10, 30, pdfWidth, pdfHeight);
        pdf.text(date, 10, 15);
        pdf.text(
          `Request by: ${localStorage.getItem("harpiaUser")}`,
          15,
          pageHeight - 10
        );
      }
      this.isDownloadLoading = false;
      this.notifier("PDF is ready", "success");
      pdf.save(`Dashboard_${timestamp}.pdf`);
    },
  },
};
</script>

<style scoped>
.box {
  -webkit-box-shadow: 0px -1px 15px 0px rgba(181, 181, 181, 1);
  -moz-box-shadow: 0px -1px 15px 0px rgba(181, 181, 181, 1);
  box-shadow: 0px -1px 15px 0px rgba(181, 181, 181, 1);
}
:deep(.data) {
  max-height: 1000px;
  transition: max-height 0.6s ease-in;
  overflow: hidden;
  opacity: 1;
}
:deep(.hidden) {
  max-height: 0px;
  transition: max-height 0.6s ease-out;
  overflow: hidden;
}
:deep(.btn-hidden) {
  opacity: 0;
  transition: opacity 0.6s ease-out;
}
:deep(.arrow) {
  width: 48px;
  height: 48px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  font-size: 1.5rem;
  transition: transform 0.6s ease-out;
}
:deep(.arrow-hidden) {
  transform: rotate(-90deg);
  transition: transform 0.6s ease-out;
}
.download-pdf-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 9999;
}
</style>
