<template>
  <div>
    <div class="field has-addons">
      <p class="control">
        <a class="button is-static is-disabled">
          Harpia_
        </a>
      </p>
      <div class="control">
        <input class="input" type="text" size="10" placeholder="123456" v-model="idInput">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    alertId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      idInput: this.alertId,
    };
  },
  watch: {
    idInput: {
      handler(idInput) {
        if (! /^\d+$/.test(idInput)) {
          this.idInput = idInput.replace(/\D/g, '');
        }
        if (idInput.length >= 13) {
          // limite 13 porque é o tamanho da timestamp
          this.idInput = this.idInput.substring(0, 13);
        }
        this.$emit("alert-id", this.idInput);
      },
    },
    alertId: {
      immediate: true,
      handler(alertId) {
        if (alertId == "") {
          this.idInput = "";
        }
      }
    },
  },
};
</script>
