<template>
  <!-- This template displays a grid of widgets, each with its own component -->
  <div>
    <div class="columns is-desktop my-1 mx-1">
      <div class="column">
        <!-- This widget displays alerts for redzones -->
        <div class="widget redzone m-auto tooltip">
          <dashboard-widget-redzones-alerts
            v-if="widgetsMonthDayRedzonesAlerts.length"
            :dates="widgetsMonthDayDates"
            :redzonesAlerts="widgetsMonthDayRedzonesAlerts"
          />
          <span class="tooltiptext">Alerts for Red Zones in the last 4 days, with percentages for comparison to the day before</span>
        </div>
      </div>

      <div class="column">
        <!-- This widget displays alerts for PPEs -->
        <div class="widget ppe m-auto tooltip">
          <dashboard-widget-PPEs-alerts
            v-if="widgetsMonthDayPPEsAlerts.length"
            :dates="widgetsMonthDayDates"
            :ppesAlerts="widgetsMonthDayPPEsAlerts"
          />
          <span class="tooltiptext">Alerts for PPE nonconformity in the last 4 days, with percentages for comparison to the day before</span>
        </div>
      </div>
      <div class="column">
        <!-- This widget displays alerts for the last 4 days -->
        <div class="widget last-four-days m-auto tooltip">
          <dashboard-widget-today-alerts
            v-if="widgetsMonthDayTodayAlerts.length"
            :dates="widgetsMonthDayDates"
            :todayAlerts="widgetsMonthDayTodayAlerts"
          />
          <span class="tooltiptext">Total alerts in the last 4 days, with percentages for comparison to the day before</span>
        </div>
      </div>
      <div class="column">
        <!-- This widget displays alerts for the current month -->
        <div class="widget last-mounth m-auto tooltip">
          <dashboard-widget-this-month />
          <span class="tooltiptext">Total alerts for this month, with percentages for comparison to the month before</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardWidgetRedzonesAlerts from "@/components/DashboardWidgetRedzonesAlerts.vue";
import DashboardWidgetPPEsAlerts from "@/components/DashboardWidgetPPEsAlerts.vue";
import DashboardWidgetTodayAlerts from "@/components/DashboardWidgetTodayAlerts.vue";
import DashboardWidgetThisMonth from "@/components/DashboardWidgetThisMonth.vue";

export default {
  name: "Widget",
  components: {
    DashboardWidgetRedzonesAlerts,
    DashboardWidgetPPEsAlerts,
    DashboardWidgetTodayAlerts,
    DashboardWidgetThisMonth,
  },
  computed: {
    widgetsMonthDayDates() {
      // returns an array of dates for the widgets
      return this.$store.getters['widget/getWidgetsMonthDayDates'];
    },
    widgetsMonthDayRedzonesAlerts() {
      // returns an array of redzone alerts for the widgets
      return this.$store.getters['widget/getWidgetsMonthDayRedzonesAlerts'];
    },
    widgetsMonthDayPPEsAlerts() {
      // returns an array of PPE alerts for the widgets
      return this.$store.getters['widget/getWidgetsMonthDayPPEsAlerts'];
    },
    widgetsMonthDayTodayAlerts() {
      // returns an array of total alerts for the widgets
      return this.$store.getters['widget/getWidgetsMonthDayTodayAlerts'];
    },
  },
  created() {
    // Action responsible for fetching the data needed for the widgets.
    this.$store.dispatch("widget/getWidgetMonthDayData");
  },
};
</script>

<style scoped>
.widget {
  padding: 0;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  display: block;
  padding: 0.75rem;
}
.redzone {
  background-color: #d85e56;
}
.ppe {
  background-color: #f0b732;
}
.last-four-days {
  background-color: #5092fb;
}
.last-mounth {
  background-color: #52b761;
}

@media (min-width: 1024px) {
  .widget {
    max-width: 300px;
  }
}
</style>
