<template>
  <div>
    <div v-if="latest_alerts.length" class="mt-4">Page {{ page }}</div>

    <div
      v-if="latest_alerts.length"
      class="columns has-text-black is-multiline mr-2 mt-3"
    >
      <div
        class="column is-6"
        v-for="alert in latest_alerts"
        v-bind:key="alert.timestamp"
      >
        <alerts-card :Alert="alert" 
        @set-timer="set_alerts_timer"
        />
      </div>
    </div>
    <div
      v-else
      class="column is-12 is-half-screen-height has-vertical-centered-text"
    >
      <i icon="alert" size="is-large" type="is-dark"></i>
      <p class="title">No alerts available.</p>
    </div>
  </div>
</template>

<script>
import AlertsCard from '@/components/AlertsCard.vue'

export default {
  components: {
    AlertsCard,
  },
  props: {
    latest_alerts: {
      type: Object,
    },
    page: {
      type: [Number, String],
    },
  },
  methods: {
    set_alerts_timer(timer) {
      this.$emit('set-timer', timer)
    },
  }
};
</script>

<style></style>
