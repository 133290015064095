<template>
  <div>
    <button
      :class="{ 'is-primary': dayFocus }"
      class="button mx-1 tooltip"
      @click="selectDay"
    >
      <span class="icon is-small">
        <i class="fas fa-calendar-alt"></i>
      </span>
      <span>Day</span>
      <span class="tooltiptext smalltooltip">Filter alerts by date</span>
    </button>
    <button
      :class="{ 'is-primary': periodFocus }"
      class="button tooltip"
      @click="selectPeriod"
    >
      <span class="icon is-small">
        <i class="fas fa-calendar-alt"></i>
      </span>
      <span>Period</span>
      <span class="tooltiptext smalltooltip">Filter alerts by period</span>
    </button>
    <button
      :class="{ 'is-primary': isIdSearch }"
      class="button ml-1 tooltip"
      @click="selectId"
    >
      <span class="icon is-small">
        <i class="fas fa-info-circle"></i>
      </span>
      <span>ID Search</span>
      <span class="tooltiptext">Search a specific alert by its unique ID</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "SelectTypeButton",
  data() {
    return {
      dayFocus: true,
      periodFocus: false,
      isDaySelected: true,
      isIdSearch: false,
    };
  },
  methods: {
    selectDay() {
      this.isDaySelected = true;
      this.dayFocus = true;
      this.periodFocus = false;
      this.isIdSearch = false;
      this.$emit("select-type", this.isDaySelected);
      this.$emit("select-type-id", this.isIdSearch);
    },
    selectPeriod() {
      this.isDaySelected = false;
      this.dayFocus = false;
      this.periodFocus = true;
      this.isIdSearch = false;
      this.$emit("select-type", this.isDaySelected);
      this.$emit("select-type-id", this.isIdSearch);
    },
    selectId() {
      this.dayFocus = false;
      this.periodFocus = false;
      this.isIdSearch = true;
      this.$emit("select-type-id", this.isIdSearch);
    },
  },
  watch: {
    isIdSearch: {
      handler() {
        console.log(this.isIdSearch);
      }
    }
  }
};
</script>
