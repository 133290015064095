<template>
  <div>
    <!--Limpar -->
    <button
      class="button is-danger is-outlined is-fullwidth focus mt-1 is-small tooltip redzonebtn"
      :class="{'is-loading': isLoading}"
      @click="clear()"
      :disabled="this.points.length < 1"
    >
      <span class="icon">
        <i class="fas fa-broom" />
      </span>
      <span>Clear</span>
      <span class="tooltiptext redzonetooltip">Clear the Red Zone drawing</span>
    </button>

    <!--Desfazer -->
    <button
      class="button is-warning is-outlined is-fullwidth focus mt-1 is-small tooltip redzonebtn"
      :class="{'is-loading': isLoading}"
      @click="undo"
      :disabled="this.points.length < 1"
    >
      <span class="icon">
        <i class="fas fa-undo" />
      </span>
      <span>Undo</span>
      <span class="tooltiptext redzonetooltip">Undo last action</span>
    </button>

    <!--Salvar, exibe o modal -->
    <button
      v-if="!rzSelected"
      class="button is-success is-outlined is-fullwidth focus mt-1 is-small tooltip redzonebtn"
      :class="{'is-loading': isLoading}"
      @click="showSaveModal"
      :disabled="this.points.length < 6"
    >
      <span class="icon">
        <i class="fas fa-save" />
      </span>
      <span>Save</span>
      <span class="tooltiptext redzonetooltip">Save the Red Zone created</span>
    </button>

    <!--Edita Redzones, exibe o modal -->
    <button
      v-else
      class="button is-success is-outlined is-fullwidth focus mt-1 is-small tooltip redzonebtn"
      :class="{'is-loading': isLoading}"
      @click="updateRedZone(rzSelected)"
      :disabled="this.points.length < 6"
    >
      <span class="icon">
        <i class="fas fa-edit" />
      </span>
      <span>Update</span>
      <span class="tooltiptext redzonetooltip">Update loaded Red Zone</span>
    </button>

    <!--ACTIVE-->
    <button
      class="button is-success is-outlined is-fullwidth focus mt-1 is-small tooltip redzonebtn"
      :class="{'is-loading': isLoading}"
      :disabled="this.rzSelected === '' || this.buttonActive === false"
      @click="enableRedZone(rzSelected)"
    >
      <span class="icon">
        <i class="fas fa-play" />
      </span>
      <span>Activate</span>
      <span class="tooltiptext redzonetooltip">Activate loaded Red Zone</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "ActionsButtons",
  props: {
    points: {
      type: Array,
    },
    rzSelected: {
      type: [String, Object],
    },
    buttonActive: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    }
  },
  methods: {
    clear() {
      this.$emit("clear");
    },
    undo() {
      this.$emit("undo");
    },
    showSaveModal() {
      this.$emit("showSaveModal");
    },
    updateRedZone(rzSelected) {
      this.$emit("updateRedZone", rzSelected);
    },
    enableRedZone(rzSelected) {
      this.$emit("enableRedZone", rzSelected);
    }
  },
};
</script>

<style></style>
