import { createRouter, createWebHistory } from "vue-router";

import store from "../store";

import LogIn from "../views/LogIn.vue";
import Alerts from "../views/Alerts.vue";
import RedZones from "../views/RedZones.vue";
import Cameras from "../views/Cameras.vue";
import Overview from "../views/Overview.vue";
import Dashboard from "../views/Dashboard.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: LogIn,
  },
  {
    path: "/log-in",
    name: "LogIn",

    component: LogIn,
  },
  {
    path: "/red-zones",
    name: "RedZones",
    component: RedZones,
  },
  {
    path: "/latest-alerts",
    name: "LatestAlerts",
    component: Alerts,
  },
  {
    path: "/cameras",
    name: "Cameras",
    component: Cameras,
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.state.isAuthenticated && to.path != "/log-in") {
    next({ name: "LogIn", query: { to: to.path } });
  } else if (
    (to.path == "/log-in" || to.path == "/") &&
    store.state.isAuthenticated
  ) {
    next({ name: "LatestAlerts" });
  } else {
    next();
  }
});

export default router;
