<template>
  <div>
    <harpiaBar />
    <div class="pagebody">
      <div class="testingwatermark" v-if="TestMode">
        <h1>
          <i class="fa-solid fa-triangle-exclamation"></i>
          <br />
          System in Testing
        </h1>
      </div>

      <div>
        <div class="columns">
          <div class="column is-2 mx-4">
            <red-zones-cameras-select-button
              :selectedCamera="camSelected"
              @update-selected-camera="updateSelectedCamera"
            />

            <div class="mt-5 is-size-7 has-text-weight-bold">
              Number of people allowed:
            </div>
            <vue-number-input
              v-model="limitPerson"
              :min="0"
              :max="10"
              size="small"
              center
              controls
              rounded
            ></vue-number-input>

            <red-zones-actions-buttons
              :points="points"
              :rzSelected="rzSelected"
              :buttonActive="buttonActive"
              :isLoading="isLoading"
              @clear="clear"
              @undo="undo"
              @show-save-modal="saveModal = true"
              @update-red-zone="updateRedZone"
              @enable-red-zone="enableRedZone"
            />

            <red-zones-load-dropdown
              class="has-text-left"
              :redzones="redzones"
              :rzSelected="rzSelected.name"
              @selectRedZone="selectRedZone"
              @deleteRedZone="deleteRedZone"
            />

            <red-zones-actives
              :activeRedzones="activeRedzones"
              @disabledRedZone="disabledRedZone"
            />
          </div>

          <div class="cams column is-10 mt-4">
            <br />
            <red-zones-image
              :stageConfig="stageConfig"
              :redZoneImage="selectedCameraImage"
              :points="points"
              :anchors="anchors"
              :scale="scale"
            />
          </div>
        </div>

        <!-- modal -->
        <red-zones-save-modal
          v-if="saveModal"
          :new-redzone="newRedzone"
          :redzones="redzones"
          @save="save"
          @cancel="saveModal = !saveModal"
          @update-red-zone-name-from-save-modal-input="
            updateRedZoneNameFromSaveModalInput
          "
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/extrastyles_redzone.scss";
</style>

<script>
import axios from "axios";
import HarpiaBar from "@/components/HarpiaBar.vue";
import { useToast } from "vue-toastification";
import notificator from "../mixins/notificator";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import RedZonesSaveModal from "@/components/RedZonesSaveModal.vue";
import RedZonesCamerasSelectButton from "@/components/RedZonesCamerasSelectButton.vue";
import RedZonesActionsButtons from "@/components/RedZonesActionsButtons.vue";
import RedZonesLoadDropdown from "@/components/RedZonesLoadDropdown.vue";
import RedZonesActives from "../components/RedZonesActives.vue";
import RedZonesImage from "../components/RedZonesImage.vue";

export default {
  name: "Redzones",
  components: {
    HarpiaBar,
    VueNumberInput,
    RedZonesSaveModal,
    RedZonesCamerasSelectButton,
    RedZonesActionsButtons,
    RedZonesLoadDropdown,
    RedZonesActives,
    RedZonesImage,
  },
  data() {
    return {
      scale: null, // a escala das imagens deve ser fixa pois os pontos são pegos em relação a posição do mouse
      stageConfig: [], // array onde as caracteristicas das imagens são armazenadas
      redzones: [], // array de redzones
      activeRedzones: [], //array de redzones ativas
      camSelected: "cam0", // camera em operação
      anchors: [],
      points: [],
      saveModal: false,
      newRedzone: "",
      rzSelected: "",
      buttonActive: false,
      limitPerson: 0,
      isResized: null,
      isLoading: false,
      TestMode: "",
      redZoneImageUrl: null,
    };
  },
  mixins: [notificator],
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    this.TestModeSet();
  },
  async created() {
    await this.fetchRedZonesImage();
    this.loadFirstRedZoneImage();
    this.loadRedZones();
    document.title = "Red Zones | Harpia";
  },
  watch: {
    camSelected: {
      handler() {
        this.clear();
        this.loadRedZones();
        this.fetchRedZonesImage();
      },
    },
  },
  computed: {
    selectedCameraImage() {
      let cameraImage = new Image();
      cameraImage.src = this.redZoneImageUrl;
      return cameraImage;
    },
  },
  methods: {
    async fetchRedZonesImage() {
      try {
        const url = `${process.env.VUE_APP_NGINX_URL}/redzones_images/${this.camSelected}_redzone.jpg`;
        const response = await axios.get(url, {
          responseType: "blob",
        });
        const blob = URL.createObjectURL(response.data);
        this.redZoneImageUrl = blob;
      } catch (error) {}
    },
    TestModeSet() {
      var TestBool = process.env.VUE_APP_TestMode == "true";
      this.TestMode = Boolean(TestBool);
      //casts string from .env to boolean
    },
    selectRedZone(rz) {
      this.clear();

      const dots = this.isResized ? rz.dots.map((x) => x / 2) : rz.dots;

      this.buttonActive = true;
      this.points = dots;
      this.anchors = this.updateAnchorsWhenSelectRedZone(dots);
      this.limitPerson = rz.limite;
      this.rzSelected = rz;
      this.buttonActive = this.checkSelectRedZoneIsActive(rz.name);
      this.loadRedZones();
    },
    updateAnchorsWhenSelectRedZone(dots) {
      const anchors = [];

      for (let i = 0; i < dots.length; i += 2) {
        const x = dots[i];
        const y = dots[i + 1];
        const id = Math.floor(Math.random() * 10000).toString();
        anchors.push({ id, x, y });
      }

      return anchors;
    },
    checkSelectRedZoneIsActive(redZoneName) {
      return !this.activeRedzones.some(
        (activeRedZone) => activeRedZone.name == redZoneName
      );
    },
    loadFirstRedZoneImage() {
      this.selectedCameraImage.onload = () => {
        let imageWidth = this.selectedCameraImage.naturalWidth;
        let imageHeight = this.selectedCameraImage.naturalHeight;

        if (imageWidth > 1280) {
          this.isResized = true;
          this.scale = 0.5;
          imageWidth = imageWidth / 2;
          imageHeight = imageHeight / 2;
        } else {
          this.isResized = false;
          this.scale = 1;
        }

        this.stageConfig = {
          name: "redZoneImage",
          width: imageWidth,
          height: imageHeight,
        };
      };
    },
    async loadRedZones() {
      try {
        this.isLoading = true;
        this.redzones = [];
        this.activeRedzones = [];
        const camera = this.camSelected.match(/\d+/g).join("");

        const response = await axios.get(`api/v2/redzones/load_rz/${camera}`);
        this.redzones = response.data;
        this.activeRedzones = response.data.filter(
          (redzone) => redzone.enabled
        );
      } catch (error) {
        const errorMessage = `Failed to load Red zones: ${error.message}`;
        this.notifier(errorMessage, "error");
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        const camera = this.camSelected.match(/\d+/g).join(""); // Get only numbers from camera name
        const dots = this.getRedZonePoints(this.points);
        var red_zone_output = JSON.stringify({
          cam: camera,
          name: this.newRedzone,
          width: this.stageConfig.width,
          height: this.stageConfig.height,
          limit: this.limitPerson,
          dots: dots,
        });

        const response = await axios.post(
          "/api/v2/redzones/save_red_zone",
          red_zone_output,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        const redZone = response.data;
        this.notifier(`Redzone "${redZone.name}" registered.`, "success");

        this.newRedzone = "";
        this.loadRedZones(); // para recarregar a redzones no botão load
        this.selectRedZone(redZone);
        this.$store.dispatch("sendUserLog", "RZ_CREATED");
      } catch (error) {
        const errorMessage = `Failed to create redzone: ${error.message}`;
        this.notifier(errorMessage, "error");
      } finally {
        this.saveModal = false;
        this.isLoading = true;
      }
    },
    clear() {
      this.points = "";
      this.rzSelected = "";
      this.points = [];
      this.anchors = [];
      this.buttonActive = false;
      this.limitPerson = 0;
    },
    undo() {
      this.points.pop();
      this.points.pop();
      this.anchors.pop();
      this.buttonActive = false;
    },
    async deleteRedZone(rz) {
      try {
        this.isLoading = true;
        await axios.get("api/v2/redzones/deldots/" + rz.id);
        this.notifier("Redzone excluded.", "success");
        this.clear();
        this.loadRedZones();
        this.$store.dispatch("sendUserLog", "RZ_DELETED");
      } catch (error) {
        const errorMessage = `Failed to delete red zone: ${error.message}`;
        this.notifier(errorMessage, "error");
      } finally {
        this.isLoading = false;
      }
    },
    async enableRedZone(rz) {
      try {
        this.isLoading = true;
        const response = await axios.get(
          "api/v2/redzones/update_red_zone/" + rz.id + "/True"
        );
        const redZoneName = response.data.name;
        this.notifier(`Redzone "${redZoneName}" activated.`, "success");
        this.loadRedZones();
        this.buttonActive = false;
        this.clear();
        this.$store.dispatch("sendUserLog", "RZ_ACTIVATED");
      } catch (error) {
        const errorMessage = `Failed to activate redzone: ${error.message}`;
        this.notifier(errorMessage, "error");
      } finally {
        this.isLoading = false;
      }
    },
    async updateRedZone(rz) {
      try {
        this.isLoading = true;
        const camera = this.camSelected.match(/\d+/g).join(""); // Get only numbers from camera name
        const dots = this.getRedZonePoints(this.points);

        var red_zone_output = JSON.stringify({
          cam: camera,
          name: rz.name,
          width: this.stageConfig.width,
          height: this.stageConfig.height,
          limit: this.limitPerson,
          dots: dots,
        });
        const response = await axios.post(
          "api/v2/redzones/edit_red_zone_dots/" + rz.id,
          red_zone_output,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        const redZone = response.data;
        this.notifier(`Redzone "${redZone.name}" changed.`, "success");
        this.clear();
        this.loadRedZones();
        this.selectRedZone(redZone);
      } catch (error) {
        const errorMessage = `Failed to Alter redzone: ${error.message}`;
        this.notifier(errorMessage, "error");
      } finally {
        this.isLoading = false;
      }
    },
    async disabledRedZone(rz) {
      try {
        this.isLoading = true;
        const response = await axios.get(
          "api/v2/redzones/update_red_zone/" + rz.id + "/False"
        );
        const redZoneName = response.data.name;
        this.notifier(`Redzone "${redZoneName}" inactivated.`, "success");
        this.loadRedZones();
        this.$store.dispatch("sendUserLog", "RZ_DEACTIVATED");
      } catch (error) {
        const errorMessage = `Failed to inactive red zone: ${error.message}`;
        this.notifier(errorMessage, "error");
      } finally {
        this.isLoading = false;
      }
    },
    updateRedZoneNameFromSaveModalInput(newName) {
      this.newRedzone = newName;
    },
    updateSelectedCamera(camera) {
      this.camSelected = camera;
    },
    getRedZonePoints(points) {
      return this.isResized ? points.map((x) => x * 2) : points;
    },
  },
};
</script>
