<template>
  <div>
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Total</p>
          <p class="title">{{ total.redzones }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Total Activated</p>
          <p class="title">{{ total.activatedRedzones }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Total Disabled</p>
          <p class="title">{{ total.disabledRedzones }}</p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
    props: {
        total: {
            type: Object,
            require: true
        }
    }
};
</script>